import { isRejectedWithValue } from "@reduxjs/toolkit";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { showNotification } from "../actions/notification";

export const requestErrorMiddleware = api => next => action => {
  if (isRejectedWithValue(action)) {
    if (action.payload.data)
      api.dispatch(
        showNotification({
          ...DEFAULT_NOTIFICATION_PAYLOAD,
          message: {
            title: "Error",
            description: action.payload.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
  }

  return next(action);
};
