import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import {
  RECEIVE_CREATE_DEMO_ACCESS,
  RECEIVE_CREATE_DEMO_ACCESS_ERROR,
  REQUEST_CREATE_DEMO_ACCESS,
} from "@/redux/constants/contact_form";
import * as contactFormApi from "@/api/contact_form";
import { showNotification } from "@/redux/actions/notification";

function requestCreateDemoAccess() {
  return {
    type: REQUEST_CREATE_DEMO_ACCESS,
  };
}

function receiveCreateDemoAccess() {
  return {
    type: RECEIVE_CREATE_DEMO_ACCESS,
  };
}

function receiveCreateDemoAccessError(error) {
  return {
    type: RECEIVE_CREATE_DEMO_ACCESS_ERROR,
    error,
  };
}

export function createDemoAccess(payload, callback) {
  return async function (dispatch) {
    dispatch(requestCreateDemoAccess());

    const response = await contactFormApi.createDemoAccess(payload);
    if (response.ok) {
      dispatch(receiveCreateDemoAccess());
      dispatch(
        showNotification({
          ...DEFAULT_NOTIFICATION_PAYLOAD,
          message: {
            ...DEFAULT_NOTIFICATION_PAYLOAD.message,
            title: "Congratulations on signing up!",
            description:
              "We appreciate your interest and look forward to helping you get started. You can expect to receive a follow-up email shortly containing instructions on how to log in. Additionally, a member of our team will be in touch with you soon to provide further information and answer any questions you may have.",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
              persist: true,
            },
          },
        }),
      );
      if (callback) callback();
    } else {
      dispatch(receiveCreateDemoAccessError(response.userDescription));
      dispatch(
        showNotification({
          ...DEFAULT_NOTIFICATION_PAYLOAD,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}
