import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics, sendCollectionToAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { requestCloseSlideOver } from "@/redux/actions/slide_over";
import { requestCloseModal } from "@/redux/actions/modal";
import { setProcessingState } from "@/redux/reducers/modalConfirmation";

export const defaultBorrowerNeedsListParameters = {
  page: 1,
  size: 50,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const borrowerNeedsListService = createApi({
  reducerPath: "borrowerNeedsListApi",
  tagTypes: ["BorrowerNeedsList"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getBorrowerNeedsList: builder.query({
      query: ({ loanId, borrowerId, ...params }) =>
        `loans/${loanId}/borrowers/${borrowerId}/needs-list?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ needListId }) => ({ type: "BorrowerNeedsList", needListId })),
              { type: "BorrowerNeedsList", id: "LIST" },
            ]
          : [{ type: "BorrowerNeedsList", id: "LIST" }],
    }),
    addBorrowerNeedsList: builder.mutation({
      query: ({ loanId, borrowerId, ...body }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Folders(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    addBorrowerNeedsListFromTemplate: builder.mutation({
      query: ({ loanId, borrowerId, needsListTemplateId }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/templates/${needsListTemplateId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Folder(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateBorrowerNeedsList: builder.mutation({
      query: ({ loanId, borrowerId, needListId, status }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/status/${status}`,
        method: "PUT",
      }),
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Folder status has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    notifyBorrowerOutstandingNeedsList: builder.mutation({
      query: ({ loanId, borrowerId }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/outstanding`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_BORROWER_REMINDER_SENT, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Reminder has been sent to the borrower!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    sortBorrowerNeedsListPosition: builder.mutation({
      query: ({ loanId, borrowerId, ...body }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/order`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
    }),
    deleteBorrowerNeedsList: builder.mutation({
      query({ loanId, borrowerId, needListId }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Folder removed from the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetBorrowerNeedsListQuery,
  useAddBorrowerNeedsListMutation,
  useAddBorrowerNeedsListFromTemplateMutation,
  useUpdateBorrowerNeedsListMutation,
  useNotifyBorrowerOutstandingNeedsListMutation,
  useSortBorrowerNeedsListPositionMutation,
  useDeleteBorrowerNeedsListMutation,
} = borrowerNeedsListService;
