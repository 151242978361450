import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { requestCloseSlideOver } from "@/redux/actions/slide_over";
import { requestCloseModal } from "@/redux/actions/modal";
import { setProcessingState } from "@/redux/reducers/modalConfirmation";
import { borrowerNeedsListService } from "@/redux/services/borrowerNeedsListService";

export const defaultDocumentsFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const documentService = createApi({
  reducerPath: "documentApi",
  tagTypes: ["Document"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getDocuments: builder.query({
      query: params => `documents?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ id }) => ({ type: "Document", id })), { type: "Document", id: "LIST" }]
          : [{ type: "Document", id: "LIST" }],
    }),
    getDocument: builder.query({
      query: id => `documents/${id}`,
      providesTags: (result, error, id) => [{ type: "Document", id }],
    }),
    addDocument: builder.mutation({
      query: body => ({
        url: "documents",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Document", id: "LIST" }],
      async onQueryStarted({ closeSlide = true }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.DOCUMENT_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.name} has been created!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          if (closeSlide) dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateDocument: builder.mutation({
      query: ({ documentId: id, ...patch }) => ({
        url: `documents/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Document", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.DOCUMENT_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Document has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(borrowerNeedsListService.util.invalidateTags(["BorrowerNeedsList"]));
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteDocument: builder.mutation({
      query(id) {
        return {
          url: `documents/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.DOCUMENT_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Document has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useUpdateDocumentMutation,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
} = documentService;
