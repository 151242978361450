import PropTypes from "prop-types";
import { NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";

export const pagePropsShape = PropTypes.shape({
  initialReduxState: PropTypes.any,
  user: PropTypes.object,
});

export const notificationMessageOptionsShape = PropTypes.shape({
  variant: PropTypes.oneOf([NOTIFICATION_VARIANTS.SUCCESS, NOTIFICATION_VARIANTS.ERROR, NOTIFICATION_VARIANTS.WARNING])
    .isRequired,
  action: PropTypes.element,
});

export const notificationMessageShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  options: notificationMessageOptionsShape,
});
