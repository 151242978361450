import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { contactService } from "./contactService";
import { loanService } from "./loanService";
import { lenderCallLogService } from "./lenderCallLogService";

export const lenderCriteriaService = createApi({
  reducerPath: "lenderCriteriaApi",
  tagTypes: ["LenderCriteria", "LenderCriterion"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderCriteria: builder.query({
      query: id => `lenders/${id}/criteria`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ lenderCriteriaId }) => ({ type: "LenderCriterion", lenderCriteriaId })),
              { type: "LenderCriteria", id: "LIST" },
            ]
          : [{ type: "LenderCriteria", id: "LIST" }],
    }),
    getLenderCriterion: builder.query({
      query: ({ lenderId, lenderCriteriaId }) => `lenders/${lenderId}/criteria/${lenderCriteriaId}`,
      providesTags: ({ lenderCriteriaId }) => [{ type: "LenderCriterion", lenderCriteriaId }],
    }),
    addLenderCriteria: builder.mutation({
      query: ({ lenderId: id, ...body }) => ({
        url: `lenders/${id}/criteria`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LenderCriteria", "LenderCriterion"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_CREATED, data);

          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender criteria have been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLenderCriteria: builder.mutation({
      query: ({ lenderId: id, lenderCriteriaId, ...body }) => ({
        url: `lenders/${id}/criteria/${lenderCriteriaId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["LenderCriteria", "LenderCriterion"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_UPDATED, data);

          dispatch(contactService.util.invalidateTags(["Contact"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender criteria have been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLenderCriteria: builder.mutation({
      query: ({ lenderId: id, lenderCriteriaId }) => ({
        url: `lenders/${id}/criteria/${lenderCriteriaId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LenderCriteria"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_CRITERIA_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender criteria have been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLenderCriteriaQuery,
  useGetLenderCriterionQuery,
  useAddLenderCriteriaMutation,
  useUpdateLenderCriteriaMutation,
  useDeleteLenderCriteriaMutation,
} = lenderCriteriaService;
