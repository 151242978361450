import React from "react";
import { scroller } from "react-scroll";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Container } from "@/components/intro/Container";
import { AnimateText } from "@/components/intro/AnimateText";
import { Button } from "@/components/button/button";

export function Pricing() {
  return (
    <section id="pricing" aria-label="Pricing" className="relative overflow-hidden py-32 bg-slate-50">
      <Container className="relative">
        <AnimateText className="flex flex-col space-y-10 lg:flex-row mt-28 lg:space-y-0 lg:space-x-20">
          <PriceTier />
        </AnimateText>
      </Container>
    </section>
  );
}

const includedFeatures = [
  "Unlimited loan intake entries",
  "Unlimited customized Needs List",
  "Unlimited automated email notifications to your borrowers and referral partners",
  "Unlimited lender call log entries",
  "Add unlimited team members to collaborate on deals",
  "SmartSort functionality to quickly identify best-fit lenders to place your deal",
  "Access to Fundview’s preferred contacts of commercial and institutional lenders",
  "And much more",
];

function PriceTier() {
  return (
    <div>
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              One price plan for you and your team
            </h2>
            <p className="mt-4 text-xl text-gray-600">Must be a verified commercial mortgage broker</p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2" />
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                  Pay monthly. Cancel anytime.
                </h3>
                <p className="mt-6 text-base text-gray-500">
                  If you are a CRE loan broker, you can now obtain access and take advantage of the entire range of
                  tools and features available to users.
                </p>
                <p className="mt-6 text-base text-gray-500" />
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 text-base font-semibold text-indigo-600">What&apos;s included</h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                    {includedFeatures.map(feature => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-100 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                <div className="mt-4 flex items-center justify-center font-bold tracking-tight text-gray-900">
                  <span className="font-medium text-xl">$</span>
                  <span className="text-5xl">299</span>
                  <span className="ml-0 mt-3 text-sm font-medium tracking-normal text-gray-500">/Mo</span>
                </div>
                <p className="mt-4 font-medium text-sm text-gray-500">Billed monthly</p>
                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <Button
                      label="Get started"
                      className="px-5 py-3 text-base w-full"
                      onClick={() => scroller.scrollTo("demo_form", { offset: -150 })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
