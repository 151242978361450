import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics, sendCollectionToAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { lenderCallLogService } from "./lenderCallLogService";
import { setProcessingState } from "../reducers/modalConfirmation";

export const lenderLoanSummaryService = createApi({
  reducerPath: "lenderLoanSummaryApi",
  tagTypes: ["LenderLoanSummary", "LenderLoanSummaryNotifications"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    addLenderLoanSummary: builder.mutation({
      query: ({ loanId, loanSummaryId, ...body }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/lenders`,
        method: "POST",
        body,
      }),
      invalidatesTags: result => [
        { type: "LenderLoanSummary", id: "LIST" },
        result.map(({ lenderLoanSummaryId }) => ({ type: "LenderLoanSummaryNotifications", id: lenderLoanSummaryId })),
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.LENDER_LOAN_SUMMARY_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been sent to the lender(s)!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    resendLenderLoanSummary: builder.mutation({
      query: ({ loanId, loanSummaryId, lenderLoanSummaryId }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/lenders/${lenderLoanSummaryId}/notifications`,
        method: "POST",
      }),
      invalidatesTags: ({ lenderLoanSummaryId }) => [
        { type: "LoanSummary", id: "LIST" },
        { type: "LenderLoanSummaryNotifications", id: lenderLoanSummaryId },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_LOAN_SUMMARY_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been sent to the lender!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    getLenderLoanSummaryNotifications: builder.query({
      query: ({ loanId, loanSummaryId, lenderLoanSummaryId }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/lenders/${lenderLoanSummaryId}/notifications`,
        method: "GET",
      }),
      providesTags: (result, error, { lenderLoanSummaryId }) => [
        { type: "LenderLoanSummaryNotifications", id: lenderLoanSummaryId },
      ],
    }),
    deleteLenderLoanSummary: builder.mutation({
      query: ({ loanId, loanSummaryId, lenderLoanSummaryId }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/lenders/${lenderLoanSummaryId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { loanSummaryId }) => [{ type: "LoanSummary", loanSummaryId }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_LOAN_SUMMARY_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been removed from the lender!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useAddLenderLoanSummaryMutation,
  useResendLenderLoanSummaryMutation,
  useGetLenderLoanSummaryNotificationsQuery,
  useDeleteLenderLoanSummaryMutation,
} = lenderLoanSummaryService;
