import isEmpty from "lodash/isEmpty";
import Bugsnag from "@bugsnag/js";

export const ANALYTIC_EVENTS = {
  IDENTIFY_USER: "identify_user",
  FAQ_CLICKED: "faq_clicked",
  DEMO_REQUESTED: "demo_requested",
  PRICING_CLICKED: "pricing_clicked",
  PROFILE_UPDATED: "profile_updated",
  DOCUMENT_CREATED: "document_created",
  DOCUMENT_UPDATED: "document_updated",
  DOCUMENT_DELETED: "document_deleted",
  COMPANY_CREATED: "company_created",
  COMPANY_UPDATED: "company_updated",
  COMPANY_TIMEZONE_UPDATED: "company_timezone_updated",
  LOAN_CREATED: "loan_created",
  LOAN_UPDATED: "loan_updated",
  LOAN_DELETED: "loan_deleted",
  LOAN_PROPERTY_DETAIL_UPDATED: "loan_property_detail_updated",
  LOAN_BORROWER_ADDED: "loan_borrower_added",
  LOAN_BORROWER_UPDATED: "loan_borrower_updated",
  LOAN_BORROWER_DELETED: "loan_borrower_deleted",
  LOAN_REFERRER_ADDED: "loan_referrer_added",
  LOAN_REFERRER_DELETED: "loan_referrer_deleted",
  LOAN_STATUS_CHANGED: "loan_status_changed",
  LOAN_ESTIMATED_CLOSING_DATE_CHANGED: "loan_estimated_closing_date_changed",
  LOAN_NOTE_CREATED: "loan_note_created",
  LOAN_NOTE_UPDATED: "loan_note_updated",
  LOAN_NOTE_DELETED: "loan_note_deleted",
  LOAN_EMPLOYEE_CREATED: "loan_employee_created",
  LOAN_EMPLOYEE_DELETED: "loan_employee_deleted",
  LOAN_PACKAGE_DOWNLOADED: "loan_package_downloaded",
  NEEDS_LIST_BORROWER_REMINDER_SENT: "needs_list_borrower_reminder_sent",
  NEEDS_LIST_DOCUMENT_CREATED: "needs_list_document_created",
  NEEDS_LIST_DOCUMENT_UPDATED: "needs_list_document_updated",
  NEEDS_LIST_DOCUMENT_DELETED: "needs_list_document_deleted",
  NEEDS_LIST_DOCUMENT_FILE_CREATED: "needs_list_document_file_created",
  NEEDS_LIST_DOCUMENT_FILE_CREATED_BY_BORROWER: "needs_list_document_file_created_by_borrower",
  NEEDS_LIST_DOCUMENT_FILE_APPROVED: "needs_list_document_file_approved",
  NEEDS_LIST_DOCUMENT_FILE_REJECTED: "needs_list_document_file_rejected",
  NEEDS_LIST_DOCUMENT_FILE_PREVIEW: "needs_list_document_file_preview",
  NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD: "needs_list_document_file_download",
  NEEDS_LIST_DOCUMENT_FILE_DELETED: "needs_list_document_file_deleted",
  NEEDS_LIST_DOCUMENT_FILE_DELETED_BY_BORROWER: "needs_list_document_file_deleted_by_borrower",
  LOAN_LENDER_CALL_LOG_ITEM_CREATED: "loan_lender_call_log_item_created",
  LOAN_LENDER_CALL_LOG_ITEM_UPDATED: "loan_lender_call_log_item_updated",
  LOAN_LENDER_CALL_LOG_ITEM_DELETED: "loan_lender_call_log_item_deleted",
  LOAN_SUMMARY_CREATED: "loan_summary_created",
  LOAN_SUMMARY_UPDATED: "loan_summary_updated",
  LOAN_SUMMARY_DELETED: "loan_summary_deleted",
  LENDER_LOAN_SUMMARY_CREATED: "lender_loan_summary_created",
  LENDER_LOAN_SUMMARY_DELETED: "lender_loan_summary_deleted",
  LENDER_LOAN_SUMMARY_RESPONDED: "lender_loan_summary_responded",
  LENDER_LOAN_SUMMARY_PACKAGE_DOWNLOADED: "lender_loan_summary_package_downloaded",
  LOAN_SUMMARY_NEEDS_LIST_FILE_CREATED: "loan_summary_needs_list_file_created",
  LOAN_SUMMARY_NEEDS_LIST_FILE_DELETED: "loan_summary_needs_list_file_deleted",
  LOAN_CALL_LOG_SMART_SORT: "loan_call_log_smart_sort",
  TEAM_MEMBER_CREATED: "team_member_created",
  TEAM_MEMBER_UPDATED: "team_member_updated",
  TEAM_MEMBER_DELETED: "team_member_deleted",
  TEAM_MEMBER_INVITED: "team_member_invited",
  CONTACT_CREATED: "contact_created",
  CONTACT_UPDATED: "contact_updated",
  CONTACT_DELETED: "contact_deleted",
  CONTACTS_IMPORTED: "contacts_imported",
  CONTACTS_REVIEWED: "contacts_reviewed",
  CONTACTS_DELETED: "contacts_deleted",
  LENDING_COMPANY_CREATED: "lending_company_created",
  LENDING_COMPANY_UPDATED: "lending_company_updated",
  LENDING_COMPANY_DELETED: "lending_company_deleted",
  LENDING_COMPANY_STATE_CREATED: "lending_company_state_created",
  LENDING_COMPANY_STATES_CREATED: "lending_company_states_created",
  LENDING_COMPANY_STATE_DELETED: "lending_company_state_deleted",
  LENDING_COMPANY_STATES_DELETED: "lending_company_states_deleted",
  LENDING_COMPANY_LOAN_TYPE_CREATED: "lending_company_loan_type_created",
  LENDING_COMPANY_LOAN_TYPES_CREATED: "lending_company_loan_types_created",
  LENDING_COMPANY_LOAN_TYPE_DELETED: "lending_company_loan_type_deleted",
  LENDING_COMPANY_LOAN_TYPES_DELETED: "lending_company_loan_types_deleted",
  LENDING_COMPANY_PROPERTY_TYPE_CREATED: "lending_company_property_type_created",
  LENDING_COMPANY_PROPERTY_TYPES_CREATED: "lending_company_property_types_created",
  LENDING_COMPANY_PROPERTY_TYPE_DELETED: "lending_company_property_type_deleted",
  LENDING_COMPANY_PROPERTY_TYPES_DELETED: "lending_company_property_types_deleted",
  LENDING_COMPANY_LOAN_PROGRAM_CREATED: "lending_company_loan_program_created",
  LENDING_COMPANY_LOAN_PROGRAMS_CREATED: "lending_company_loan_programs_created",
  LENDING_COMPANY_LOAN_PROGRAM_DELETED: "lending_company_loan_program_deleted",
  LENDING_COMPANY_LOAN_PROGRAMS_DELETED: "lending_company_loan_programs_deleted",
  NEEDS_LIST_TEMPLATE_CLONED: "needs_list_template_cloned",
  NEEDS_LIST_TEMPLATE_CREATED: "needs_list_template_created",
  NEEDS_LIST_TEMPLATE_UPDATED: "needs_list_template_updated",
  NEEDS_LIST_TEMPLATE_DELETED: "needs_list_template_deleted",
  BORROWER_NEEDS_LIST_TEMPLATE_CREATED: "borrower_needs_list_template_created",
  NEEDS_LIST_TEMPLATE_DOCUMENT_CREATED: "needs_list_template_document_created",
  NEEDS_LIST_TEMPLATE_DOCUMENTS_CREATED: "needs_list_template_documents_created",
  NEEDS_LIST_TEMPLATE_DOCUMENT_DELETED: "needs_list_template_document_deleted",
  NEEDS_LIST_TEMPLATE_DOCUMENTS_DELETED: "needs_list_template_documents_deleted",
  BUTTON_CLICKED: "button_clicked",
  LENDER_CALL_LOG_NOTE_CREATED: "lender_call_log_note_created",
  LENDER_CALL_LOG_NOTE_UPDATED: "lender_call_log_note_updated",
  LENDER_CALL_LOG_NOTE_DELETED: "lender_call_log_note_deleted",
  LENDER_CALL_LOG_NOTE_CREATED_BY_LENDER: "lender_call_log_note_created_by_lender",
  LENDER_CALL_LOG_NOTE_UPDATED_BY_LENDER: "lender_call_log_note_updated_by_lender",
  LENDER_CALL_LOG_NOTE_DELETED_BY_LENDER: "lender_call_log_note_deleted_by_lender",
  COMPANY_NOTIFICATION_SCHEDULE__UPDATED: "company_notification_updated",
  LENDERS_IMPORT_WIZARD: "lenders_import_wizard",
  LENDER_ATTRIBUTE_CREATED: "lender_attribute_created",
  LENDER_ATTRIBUTE_UPDATED: "lender_attribute_updated",
  LENDER_CRITERIA_CREATED: "lender_criteria_created",
  LENDER_CRITERIA_UPDATED: "lender_criteria_updated",
  LENDER_CRITERIA_DELETED: "lender_criteria_deleted",
  LENDER_CRITERIA_PARAMETER_CREATED: "lender_criteria_parameter_created",
  LENDER_CRITERIA_PARAMETER_DELETED: "lender_criteria_parameter_deleted",
  LENDER_OFFER_CREATED: "lender_offer_created",
  LENDER_OFFER_UPDATED: "lender_offer_updated",
  LENDER_OFFER_DELETED: "lender_offer_deleted",
  LENDER_OFFER_FILE_CREATED: "lender_offer_file_created",
  LENDER_OFFER_FILE_DELETED: "lender_offer_file_deleted",
  LENDER_OFFER_FILE_PREVIEW: "lender_offer_file_preview",
  LENDER_OFFER_FILE_DOWNLOAD: "lender_offer_file_download",
  BORROWER_NEEDS_LIST_INSTRUCTIONS_UPDATED: "borrower_needs_list_instructions_updated",
};

export const ANALYTICS_USER_TYPES = {
  BROKER: "broker",
  LENDER: "lender",
  REFERRER: "referrer",
  BORROWER: "borrower",
};

export function sendAnalytics(eventName, data = {}) {
  if (typeof window === "undefined") return;
  window.dataLayer = window.dataLayer || [];

  try {
    // Send events to Mixpanel
    window.mixpanel?.track(eventName, { ...data });

    // Send events to RudderStack
    window.rudderanalytics?.track(eventName, { ...data });

    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  } catch (error) {
    Bugsnag.notify(error);
  }
}

export function sendCollectionToAnalytics(eventName, collection) {
  if (!collection.length) return;

  collection.forEach(item => {
    sendAnalytics(eventName, { ...item });
  });
}

export function identifyUser(user) {
  window.dataLayer = window.dataLayer || [];

  if (isEmpty(user)) return;

  sendAnalytics(ANALYTIC_EVENTS.IDENTIFY_USER, user);
}

export function initializeUser(userId, data) {
  if (typeof window === "undefined" || !userId) return;

  identifyUser({
    userId: data.userId,
    userType: ANALYTICS_USER_TYPES.BROKER,
    roleId: data.employeeRoleId,
    firstName: data.firstName,
    lastName: data.lastName,
    fullName: `${data.firstName} ${data.lastName}`,
    email: data.email,
    companyId: data.companyId,
    companyName: data.legalName,
    avatarUrl: data.pictureUrl,
    membershipDate: new Date(data.createdAt).toISOString(),
    environment: process.env.NODE_ENV,
  });

  Bugsnag.setUser(data.userId, data.email, `${data.firstName} ${data.lastName}`);
}
