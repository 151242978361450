import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import saveAs from "file-saver";
import { getQueryParams } from "@/utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { borrowerNeedsListService } from "@/redux/services/borrowerNeedsListService";

export const defaultBorrowerNeedsListFileParameters = {
  page: 1,
  size: 50,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const borrowerNeedsListFileService = createApi({
  reducerPath: "borrowerNeedsListFileApi",
  tagTypes: ["BorrowerNeedsListFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getBorrowerNeedsListFiles: builder.query({
      query: ({ loanId, borrowerId, needListId, ...params }) =>
        `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ needListFileId }) => ({ type: "BorrowerNeedsListFile", needListFileId })),
              { type: "BorrowerNeedsListFile", id: "LIST" },
            ]
          : [{ type: "BorrowerNeedsListFile", id: "LIST" }],
    }),
    deleteBorrowerNeedsListFile: builder.mutation({
      query({ loanId, borrowerId, needListId, needListFileId }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files/${needListFileId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File removed from the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(borrowerNeedsListService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    approveBorrowerNeedsListFile: builder.mutation({
      query({ loanId, borrowerId, needListId, needListFileId }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files/${needListFileId}/approval`,
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_APPROVED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been approved!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(borrowerNeedsListService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    denyBorrowerNeedsListFile: builder.mutation({
      query({ loanId, borrowerId, needListId, needListFileId, reason }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files/${needListFileId}/denial`,
          method: "POST",
          body: { reason },
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_REJECTED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been rejected!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(borrowerNeedsListService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    previewBorrowerNeedsListFile: builder.mutation({
      query({ loanId, borrowerId, needListId, needListFileId }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files/${needListFileId}/preview`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.target = "_blank";
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_PREVIEW, data);
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadBorrowerNeedsListFile: builder.mutation({
      query({ loanId, borrowerId, needListId, needListFileId }) {
        return {
          url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files/${needListFileId}/physical`,
          method: "GET",
        };
      },
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File has been downloaded!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadLoanPackage: builder.mutation({
      queryFn: async (id, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `loans/${id}/needs-list/package`,
          responseHandler: response => response.blob(),
        });

        saveAs(result.data, "Loan Package.zip");

        api.dispatch(
          showNotification({
            ...DEFAULT_NOTIFICATION_PAYLOAD,
            message: {
              ...DEFAULT_NOTIFICATION_PAYLOAD.message,
              description: "Package has been generated!",
              options: {
                variant: NOTIFICATION_VARIANTS.SUCCESS,
              },
            },
          }),
        );

        return { data: result.data };
      },
    }),
  }),
});

export const {
  useGetBorrowerNeedsListFilesQuery,
  useDeleteBorrowerNeedsListFileMutation,
  useApproveBorrowerNeedsListFileMutation,
  useDenyBorrowerNeedsListFileMutation,
  usePreviewBorrowerNeedsListFileMutation,
  useDownloadBorrowerNeedsListFileMutation,
  useDownloadLoanPackageMutation,
} = borrowerNeedsListFileService;
