import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendCollectionToAnalytics } from "@/utils/analytics";
import { requestCloseSlideOver } from "@/redux/actions/slide_over";
import { borrowerNeedsListService } from "@/redux/services/borrowerNeedsListService";
import { borrowerDashboardService } from "@/redux/services/borrowerDashboard";
import { borrowerNeedsListFileService } from "@/redux/services/borrowerNeedsListFilesService";
import { baseUrl, onPrepareHeadersBlob } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";

export const fileProcessorService = createApi({
  reducerPath: "fileProcessorApi",
  tagTypes: ["BorrowerNeedsListFile"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeadersBlob,
  }),
  endpoints: builder => ({
    addBorrowerNeedsListFile: builder.mutation({
      query: ({ loanId, borrowerId, needListId, formData: body }) => ({
        url: `loans/${loanId}/borrowers/${borrowerId}/needs-list/${needListId}/files`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_CREATED, data.files);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(borrowerNeedsListService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
          dispatch(borrowerNeedsListFileService.util.invalidateTags([{ type: "BorrowerNeedsListFile", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    addBorrowerNeedsListFileFromDashboard: builder.mutation({
      query: ({ token, needListId, formData: body }) => ({
        url: `borrowers/${token}/needs-list/${needListId}/files`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "BorrowerNeedsListFile", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendCollectionToAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_CREATED_BY_BORROWER, data.files);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File(s) added to the borrower needs list!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(borrowerDashboardService.util.invalidateTags([{ type: "BorrowerNeedsList", id: "LIST" }]));
          dispatch(borrowerDashboardService.util.invalidateTags([{ type: "BorrowerNeedsListFile", id: "LIST" }]));
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const { useAddBorrowerNeedsListFileMutation, useAddBorrowerNeedsListFileFromDashboardMutation } =
  fileProcessorService;
