import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { lenderCallLogService } from "@/redux/services/lenderCallLogService";
import { contactService } from "@/redux/services/contactService";

export const lenderAttributeService = createApi({
  reducerPath: "lenderAttributeApi",
  tagTypes: ["LenderAttribute"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderAttributes: builder.query({
      query: id => `lenders/${id}/attributes`,
      providesTags: (result, error, id) => [{ type: "LenderAttribute", id }],
    }),
    addLenderAttribute: builder.mutation({
      query: ({ lenderId: id, ...body }) => ({
        url: `lenders/${id}/attributes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LenderAttribute"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_ATTRIBUTE_CREATED, data);
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
          dispatch(contactService.util.invalidateTags(["Contact"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender attributes have been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLenderAttribute: builder.mutation({
      query: ({ lenderId: id, ...body }) => ({
        url: `lenders/${id}/attributes`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["LenderAttribute"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_ATTRIBUTE_UPDATED, data);
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Lender attributes have been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const { useGetLenderAttributesQuery, useAddLenderAttributeMutation, useUpdateLenderAttributeMutation } =
  lenderAttributeService;
