import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { loanService } from "./loanService";

export const loanReferrerService = createApi({
  reducerPath: "loanReferrerApi",
  tagTypes: ["LoanReferrer"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    addLoanReferrer: builder.mutation({
      query: ({ loanId, referrerId }) => ({
        url: `loans/${loanId}/referrers/${referrerId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LoanReferrer", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_REFERRER_ADDED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.referrerFullName} has been added to the loan!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(loanService.util.invalidateTags(["Loan"]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoanReferrer: builder.mutation({
      query({ loanId }) {
        return {
          url: `loans/${loanId}/referrers`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LoanReferrer", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(loanService.util.invalidateTags(["Loan"]));
          sendAnalytics(ANALYTIC_EVENTS.LOAN_REFERRER_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.referrerFullName} has been removed from the loan!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const { useAddLoanReferrerMutation, useDeleteLoanReferrerMutation } = loanReferrerService;
