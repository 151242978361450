import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";

export const notificationService = createApi({
  reducerPath: "notificationApi",
  tagTypes: ["Notification", "NotificationSchedule"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getRecurrentNotification: builder.query({
      query: () => `notifications`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ notificationId }) => ({ type: "Notification", notificationId })),
              { type: "Notification", id: "LIST" },
            ]
          : [{ type: "Notification", id: "LIST" }],
    }),
    getCompanyNotificationSchedule: builder.query({
      query: id => `notifications/${id}/schedules`,
      providesTags: result =>
        result
          ? [
              ...result.map(({ weekday }) => ({ type: "NotificationSchedule", weekday })),
              { type: "NotificationSchedule", id: "LIST" },
            ]
          : [{ type: "NotificationSchedule", id: "LIST" }],
    }),
    updateCompanyNotificationSchedule: builder.mutation({
      query: ({ notificationId, weekday, startTime, isActive }) => ({
        url: `notifications/${notificationId}/schedules/${weekday}`,
        method: "POST",
        body: {
          startTime,
          isActive,
        },
      }),
      invalidatesTags: [{ type: "NotificationSchedule", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.COMPANY_NOTIFICATION_SCHEDULE__UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Schedule has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetRecurrentNotificationQuery,
  useGetCompanyNotificationScheduleQuery,
  useUpdateCompanyNotificationScheduleMutation,
} = notificationService;
