import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import saveAs from "file-saver";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { showNotification } from "../actions/notification";
import { baseUrl, onPrepareHeaders } from "./helpers";

export const lenderSummaryNeedsListService = createApi({
  reducerPath: "lenderSummaryNeedsListApi",
  tagTypes: ["LenderSummaryNeedsList"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanSummaryNeedsList: builder.query({
      query: ({ token, ...params }) => `lenders/${token}/needs-list?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              // ...result.map(({ needsListFileId }) => ({ type: "LenderSummaryNeedsList", needsListFileId })),
              { type: "LenderSummaryNeedsList", id: "LIST" },
            ]
          : [{ type: "LenderSummaryNeedsList", id: "LIST" }],
      transformResponse: items =>
        items.reduce((item, file) => {
          const records = { ...item };
          if (!item[file.borrowerId]) {
            records[file.borrowerId] = {
              borrowerName: file.borrowerName,
              documents: {
                [file.documentId]: {
                  documentName: file.documentName,
                  files: [],
                },
              },
            };
          }

          if (!records[file.borrowerId].documents[file.documentId]) {
            records[file.borrowerId].documents[file.documentId] = {
              documentName: file.documentName,
              files: [],
            };
          }

          records[file.borrowerId].documents[file.documentId].files.push({
            loanId: file.loanId,
            loanSummaryId: file.loanSummaryId,
            borrowerId: file.borrowerId,
            needsListId: file.needsListId,
            needsListFileId: file.needsListFileId,
            needsListFileName: file.needsListFileName,
            isActive: file.isActive,
          });

          return records;
        }, {}),
    }),
    downloadLoanSummaryNeedsListFile: builder.mutation({
      query: ({ token, borrowerId, needsListId, needsListFileId }) => ({
        url: `lenders/${token}/borrowers/${borrowerId}/needs-list/${needsListId}/files/${needsListFileId}/physical`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { token }) => [{ type: "LenderSummaryNeedsList", token }],
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = data.url;
          a.download = data.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD, data);
        } catch (err) {
          /* empty */
        }
      },
    }),
    downloadLoanSummaryNeedsListPackage: builder.mutation({
      queryFn: async (token, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `lenders/${token}/needs-list/package`,
          responseHandler: response => response.blob(),
        });

        saveAs(result.data, "package.zip");

        api.dispatch(
          showNotification({
            ...DEFAULT_NOTIFICATION_PAYLOAD,
            message: {
              ...DEFAULT_NOTIFICATION_PAYLOAD.message,
              description: "Package has been generated!",
              options: {
                variant: NOTIFICATION_VARIANTS.SUCCESS,
              },
            },
          }),
        );

        return { data: result.data };
      },
    }),
  }),
});

export const {
  useGetLoanSummaryNeedsListQuery,
  useDownloadLoanSummaryNeedsListFileMutation,
  useDownloadLoanSummaryNeedsListPackageMutation,
} = lenderSummaryNeedsListService;
