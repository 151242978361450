import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_GET_LOAN_STATUS_ERROR,
  RECEIVE_CREATE_LOAN_STATUS_ERROR,
  RECEIVE_UPDATE_LOAN_STATUS_ERROR,
  REQUEST_GET_LOAN_STATUSES,
  RECEIVE_GET_LOAN_STATUS,
  RECEIVE_CREATE_LOAN_STATUS,
  RECEIVE_GET_LOAN_STATUSES,
  REQUEST_GET_LOAN_STATUS,
  RECEIVE_GET_LOAN_STATUSES_ERROR,
  REQUEST_CLEAR_LOAN_STATUS,
  REQUEST_CREATE_LOAN_STATUS,
  REQUEST_UPDATE_LOAN_STATUS,
  RECEIVE_UPDATE_LOAN_STATUS,
  RECEIVE_DELETE_LOAN_STATUS_ERROR,
  RECEIVE_DELETE_LOAN_STATUS,
  REQUEST_DELETE_LOAN_STATUS,
} from "../constants/loan_statuses";

export const defaultLoanStatusesFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: "",
};

const defaultState = {
  loanStatuses: [],
  loanStatus: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const loanStatuses = (state = defaultState, action) => {
  let index;
  let newLoanStatuses;

  switch (action.type) {
    case REQUEST_GET_LOAN_STATUSES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LOAN_STATUSES:
      return {
        ...state,
        loanStatuses: action.loanStatuses,
        totalPages: action.loanStatuses.totalPages,
        totalItems: action.loanStatuses.totalItems,
        hasNextPage: action.loanStatuses.hasNextPage,
        hasPreviousPage: action.loanStatuses.hasPreviousPage,
        pageNumber: action.loanStatuses.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LOAN_STATUS:
      return {
        ...state,
        loanStatus: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LOAN_STATUS:
      return {
        ...state,
        loanStatus: action.loanStatus,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_LOAN_STATUS:
      return {
        ...state,
        loanStatus: {},
      };
    case REQUEST_UPDATE_LOAN_STATUS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LOAN_STATUS:
      index = findIndex(state.loanStatuses, ["loanStatusId", action.loanStatus.loanStatusId]);
      if (index >= 0) {
        newLoanStatuses = fill([...state.loanStatuses], action.loanStatus, index, index + 1);
      }
      return {
        ...state,
        loanStatus: action.loanStatus,
        loanStatuses: newLoanStatuses || state.loanStatuses,
        isSaving: false,
      };
    case REQUEST_CREATE_LOAN_STATUS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LOAN_STATUS:
      return {
        ...state,
        loanStatus: action.loanStatus,
        isSaving: false,
      };
    case REQUEST_DELETE_LOAN_STATUS:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LOAN_STATUS:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_LOAN_STATUS_ERROR:
    case RECEIVE_CREATE_LOAN_STATUS_ERROR:
    case RECEIVE_GET_LOAN_STATUS_ERROR:
    case RECEIVE_UPDATE_LOAN_STATUS_ERROR:
    case RECEIVE_GET_LOAN_STATUSES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
