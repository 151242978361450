import { HEADERS } from "@/utils/applicationConstants";

export const baseUrl = "/api/";

export const onPrepareHeaders = (headers, { getState }) => {
  const { currentCompany } = getState().currentUser;

  headers.append("Content-Type", "application/json");
  headers.append(HEADERS.TENANT_ID, currentCompany.companyId);
  headers.append(HEADERS.APP_ID, process.env.NEXT_PUBLIC_APP_ID);
  headers.append(HEADERS.VERSION_ID, process.env.NEXT_PUBLIC_APP_VERSION);
  if (process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_CLIENT_IP) {
    headers.append("x-forwarded-for", process.env.NEXT_PUBLIC_CLIENT_IP);
  }

  return headers;
};

export const onPrepareHeadersBlob = (headers, { getState }) => {
  const { currentCompany } = getState().currentUser;

  headers.append(HEADERS.TENANT_ID, currentCompany.companyId);
  headers.append(HEADERS.APP_ID, process.env.NEXT_PUBLIC_APP_ID);
  headers.append(HEADERS.VERSION_ID, process.env.NEXT_PUBLIC_APP_VERSION);
  if (process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_CLIENT_IP) {
    headers.append("x-forwarded-for", process.env.NEXT_PUBLIC_CLIENT_IP);
  }

  return headers;
};
