import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Router from "next/router";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { requestCloseModal } from "../actions/modal";
import { setProcessingState } from "../reducers/modalConfirmation";

export const loanService = createApi({
  reducerPath: "loanApi",
  tagTypes: ["Loan", "LoanLendersHeatMap"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoans: builder.query({
      query: params => `loans?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ loanId }) => ({ type: "Loan", loanId })), { type: "Loan", id: "LIST" }]
          : [{ type: "Loan", id: "LIST" }],
    }),
    getLoan: builder.query({
      query: id => `loans/${id}`,
      providesTags: (result, error, id) => [{ type: "Loan", id }],
    }),
    getLoanLendersHeatMap: builder.query({
      query: ({ loanId, ...params }) => `loans/${loanId}/lenders-heat-map?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ lenderId }) => ({ type: "LoanLendersHeatMap", id: lenderId })),
              { type: "LoanLendersHeatMap", id: "LIST" },
            ]
          : [{ type: "LoanLendersHeatMap", id: "LIST" }],
    }),
    addLoan: builder.mutation({
      query: body => ({
        url: "loans",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Loan", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          Router.push(`/loans/${data.loanId}/property-detail`);
        } catch (err) {
          /* empty */
        }
      },
    }),
    patchLoan: builder.mutation({
      query: ({ loanId: id, event, ...patch }) => ({
        url: `loans/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { loanId: id }) => [
        { type: "Loan", id },
        { type: "LoanLendersHeatMap", id: "LIST" },
      ],
      async onQueryStarted({ event }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(event || ANALYTIC_EVENTS.LOAN_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoan: builder.mutation({
      query(id) {
        return {
          url: `loans/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Loan", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
          Router.push("/loans");
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetLoansQuery,
  useGetLoanQuery,
  useGetLoanLendersHeatMapQuery,
  useAddLoanMutation,
  usePatchLoanMutation,
  useDeleteLoanMutation,
} = loanService;
