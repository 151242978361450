import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "@/redux/services/helpers";
import { showNotification } from "@/redux/actions/notification";
import { requestCloseSlideOver } from "@/redux/actions/slide_over";
import { requestCloseModal } from "@/redux/actions/modal";
import { loanBorrowerService } from "@/redux/services/loanBorrowerService";
import { lendingCompanyService } from "@/redux/services/lendingCompanyService";
import { lenderCallLogService } from "@/redux/services/lenderCallLogService";
import { setProcessingState } from "@/redux/reducers/modalConfirmation";
import { loanService } from "@/redux/services/loanService";

export const defaultDocumentsFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const contactService = createApi({
  reducerPath: "contactApi",
  tagTypes: ["Contact", "PendingContact", "Borrower", "Lender", "Referrer"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getContactsForReview: builder.query({
      query: params => `contacts?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ contactId }) => ({ type: "PendingContact", contactId })),
              { type: "PendingContact", id: "LIST" },
            ]
          : [{ type: "PendingContact", id: "LIST" }],
    }),
    importContacts: builder.mutation({
      query: ({ body, connector }) => ({
        url: `contacts/bulk?connector=${connector}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PendingContact", id: "LIST" }],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACTS_IMPORTED, data);
        } catch (err) {
          /* empty */
        }
      },
    }),
    reviewContacts: builder.mutation({
      query: body => ({
        url: "contacts/bulk",
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "PendingContact", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACTS_REVIEWED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.contactsUpdated} contact(s) reviewed!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    deleteContacts: builder.mutation({
      query: body => ({
        url: "contacts/bulk",
        method: "DELETE",
        body,
      }),
      invalidatesTags: [{ type: "PendingContact", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACTS_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.contactsDeleted} contact(s) deleted!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    getContacts: builder.query({
      query: params => `contacts?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ contactId }) => ({ type: "Contact", contactId })), { type: "Contact", id: "LIST" }]
          : [{ type: "Contact", id: "LIST" }],
    }),
    getBorrowers: builder.query({
      query: params => `contacts?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ contactId }) => ({ type: "Borrower", contactId })),
              { type: "Borrower", id: "LIST" },
            ]
          : [{ type: "Borrower", id: "LIST" }],
    }),
    getLenders: builder.query({
      query: params => `contacts?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ contactId }) => ({ type: "Lender", contactId })), { type: "Lender", id: "LIST" }]
          : [{ type: "Lender", id: "LIST" }],
    }),
    getReferrers: builder.query({
      query: params => `contacts?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ contactId }) => ({ type: "Referrer", contactId })),
              { type: "Referrer", id: "LIST" },
            ]
          : [{ type: "Referrer", id: "LIST" }],
    }),
    getContact: builder.query({
      query: id => `contacts/${id}`,
      providesTags: (result, error, id) => [{ type: "Contact", id }],
    }),
    addContact: builder.mutation({
      query: body => ({
        url: "contacts",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        { type: "Contact", id: "LIST" },
        { type: "Borrower", id: "LIST" },
        { type: "Lender", id: "LIST" },
        { type: "Referrer", id: "LIST" },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Contact has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(lendingCompanyService.util.invalidateTags(["LendingCompany"]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateContact: builder.mutation({
      query: ({ contactId: id, ...patch }) => ({
        url: `contacts/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Contact", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Contact has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(loanBorrowerService.util.invalidateTags(["LoanBorrower"]));
          dispatch(lendingCompanyService.util.invalidateTags(["LendingCompany"]));
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));

          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteContact: builder.mutation({
      query(id) {
        return {
          url: `contacts/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        { type: "Contact", id: "LIST" },
        { type: "Borrower", id: "LIST" },
        { type: "Lender", id: "LIST" },
        { type: "Referrer", id: "LIST" },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.CONTACT_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Contact has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(loanService.util.invalidateTags(["LoanLendersHeatMap"]));
          dispatch(loanBorrowerService.util.invalidateTags(["LoanBorrower"]));

          dispatch(requestCloseSlideOver());
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetContactsForReviewQuery,
  useReviewContactsMutation,
  useDeleteContactsMutation,
  useImportContactsMutation,
  useGetContactsQuery,
  useGetBorrowersQuery,
  useGetLendersQuery,
  useGetReferrersQuery,
  useGetContactQuery,
  useAddContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = contactService;
