import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_CREATE_EMPLOYEE,
  RECEIVE_CREATE_EMPLOYEE_ERROR,
  RECEIVE_GET_EMPLOYEE,
  RECEIVE_GET_EMPLOYEE_ERROR,
  RECEIVE_GET_EMPLOYEES,
  RECEIVE_GET_EMPLOYEES_ERROR,
  RECEIVE_UPDATE_EMPLOYEE,
  RECEIVE_UPDATE_EMPLOYEE_ERROR,
  REQUEST_CLEAR_EMPLOYEE,
  REQUEST_CLEAR_EMPLOYEES,
  REQUEST_CREATE_EMPLOYEE,
  REQUEST_GET_EMPLOYEE,
  REQUEST_GET_EMPLOYEES,
  REQUEST_UPDATE_EMPLOYEE,
  RECEIVE_SEND_EMPLOYEE_INVITATION_ERROR,
  RECEIVE_SEND_EMPLOYEE_INVITATION,
  REQUEST_SEND_EMPLOYEE_INVITATION,
} from "@/redux/constants/employees";

export const defaultEmployeesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
  roles: [],
};

const defaultState = {
  employees: [],
  employee: {},
  error: null,
  isSendingInvite: false,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const employees = (state = defaultState, action) => {
  let index;
  let newEmployees;
  switch (action.type) {
    case REQUEST_SEND_EMPLOYEE_INVITATION:
      return {
        ...state,
        isSendingInvite: true,
      };
    case RECEIVE_SEND_EMPLOYEE_INVITATION:
      return {
        ...state,
        isSendingInvite: false,
      };
    case REQUEST_CREATE_EMPLOYEE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
        isSaving: false,
      };
    case REQUEST_UPDATE_EMPLOYEE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_EMPLOYEE:
      index = findIndex(state.employees, ["employeeId", action.employee.employeeId]);
      if (index >= 0) {
        newEmployees = fill([...state.employees], action.employee, index, index + 1);
      }
      return {
        ...state,
        employees: newEmployees || state.employees,
        isSaving: false,
      };
    case REQUEST_GET_EMPLOYEES:
      return {
        ...state,
        employees: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_EMPLOYEES:
      return {
        ...state,
        employees: action.employees.items,
        totalPages: action.employees.totalPages,
        totalItems: action.employees.totalItems,
        hasNextPage: action.employees.hasNextPage,
        hasPreviousPage: action.employees.hasPreviousPage,
        pageNumber: action.employees.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_EMPLOYEE:
      return {
        ...state,
        employee: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_EMPLOYEE:
      return {
        ...state,
        employee: {},
      };
    case REQUEST_CLEAR_EMPLOYEES:
      return {
        ...state,
        employees: [],
      };
    case RECEIVE_UPDATE_EMPLOYEE_ERROR:
    case RECEIVE_GET_EMPLOYEE_ERROR:
    case RECEIVE_CREATE_EMPLOYEE_ERROR:
    case RECEIVE_GET_EMPLOYEES_ERROR:
    case RECEIVE_SEND_EMPLOYEE_INVITATION_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
        isSendingInvite: false,
      };
    default:
      return state;
  }
};
