import { MODAL_SIZES } from "@/components/modals/util";
import {
  REQUEST_OPEN_MODAL,
  REQUEST_CLOSE_MODAL,
} from '../constants/modal';

const defaultState = {
  isOpen: false,
  fragment: null,
  options: {
    size: MODAL_SIZES.SM,
  },
};

export const modal = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        fragment: action.fragment,
        options: { ...state.options, ...action.options },
      };
    case REQUEST_CLOSE_MODAL:
      return {
        ...state,
        fragment: null,
        isOpen: false,
        options: { ...defaultState.options },
      };
    default:
      return state;
  }
};
