import { fetchWithHeaders, HTTP_METHODS, parseErrorResponse, responseData } from "@/utils/httpRequest";

export function createDemoAccess(payload = {}) {
  return fetchWithHeaders("/account", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    omitToken: true,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
