import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  REQUEST_GET_LOAN_SUMMARIES,
  RECEIVE_GET_LOAN_SUMMARIES,
  REQUEST_CLEAR_LOAN_SUMMARIES,
  REQUEST_DELETE_LOAN_SUMMARY,
  RECEIVE_DELETE_LOAN_SUMMARY,
  REQUEST_CREATE_LOAN_SUMMARY,
  RECEIVE_CREATE_LOAN_SUMMARY,
  REQUEST_UPDATE_LOAN_SUMMARY,
  RECEIVE_UPDATE_LOAN_SUMMARY,
  REQUEST_CREATE_LOAN_SUMMARY_DRAFT,
  RECEIVE_CREATE_LOAN_SUMMARY_DRAFT,
  REQUEST_GET_LOAN_SUMMARY,
  RECEIVE_GET_LOAN_SUMMARY,
  REQUEST_GET_LOAN_SUMMARY_DRAFT,
  RECEIVE_GET_LOAN_SUMMARY_DRAFT,
  RECEIVE_GET_LOAN_SUMMARY_ERROR,
  RECEIVE_CREATE_LOAN_SUMMARY_ERROR,
  RECEIVE_DELETE_LOAN_SUMMARY_ERROR,
  RECEIVE_GET_LOAN_SUMMARIES_ERROR,
  RECEIVE_CREATE_LOAN_SUMMARY_DRAFT_ERROR,
  RECEIVE_GET_LOAN_SUMMARY_DRAFT_ERROR,
  RECEIVE_UPDATE_LOAN_SUMMARY_ERROR,
} from '../constants/loan_summaries';
import {
  RECEIVE_CREATE_LENDER_LOAN_SUMMARY,
  RECEIVE_CREATE_LENDER_LOAN_SUMMARY_ERROR,
  RECEIVE_DELETE_LENDER_LOAN_SUMMARY,
  RECEIVE_DELETE_LENDER_LOAN_SUMMARY_ERROR,
  RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE,
  RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE_ERROR,
  RECEIVE_GET_LENDER_LOAN_SUMMARY,
  RECEIVE_GET_LENDER_LOAN_SUMMARY_ERROR,
  RECEIVE_RESEND_LENDER_LOAN_SUMMARY,
  RECEIVE_RESEND_LENDER_LOAN_SUMMARY_ERROR,
  RECEIVE_UPDATE_LENDER_LOAN_SUMMARY,
  RECEIVE_UPDATE_LENDER_LOAN_SUMMARY_ERROR,
  REQUEST_CREATE_LENDER_LOAN_SUMMARY,
  REQUEST_DELETE_LENDER_LOAN_SUMMARY,
  REQUEST_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE,
  REQUEST_GET_LENDER_LOAN_SUMMARY,
  REQUEST_RESEND_LENDER_LOAN_SUMMARY,
  REQUEST_UPDATE_LENDER_LOAN_SUMMARY,
} from '../constants/lender_loan_summaries';

export const defaultLoanSummaryFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  loanSummaries: [],
  loanSummary: {},
  lenderLoanSummary: {},
  loan: {},
  error: null,
  isFetching: false,
  isPreviewing: false,
  isFetchingSingle: false,
  isGenerating: false,
  isSaving: false,
  isDeleting: false,
  isSending: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const loanSummaries = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_LOAN_SUMMARIES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LOAN_SUMMARIES:
      return {
        ...state,
        loanSummaries: action.loanSummaries.items,
        totalPages: action.loanSummaries.totalPages,
        totalItems: action.loanSummaries.totalItems,
        hasNextPage: action.loanSummaries.hasNextPage,
        hasPreviousPage: action.loanSummaries.hasPreviousPage,
        pageNumber: action.loanSummaries.pageNumber,
        isFetching: false,
      };
    case REQUEST_CLEAR_LOAN_SUMMARIES:
      return {
        ...state,
        loanSummaries: [],
      };
    case REQUEST_DELETE_LOAN_SUMMARY:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LOAN_SUMMARY:
      return {
        ...state,
        loanSummaries:
            state.loanSummaries.filter((item) => item.loanSummaryId !== action.loanSummary.loanSummaryId),
        isDeleting: false,
      };
    case REQUEST_CREATE_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: false,
      };
    case REQUEST_UPDATE_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: false,
      };
    case REQUEST_CREATE_LOAN_SUMMARY_DRAFT:
      return {
        ...state,
        isPreviewing: true,
      };
    case RECEIVE_CREATE_LOAN_SUMMARY_DRAFT:
      return {
        ...state,
        isPreviewing: false,
      };
    case REQUEST_GET_LOAN_SUMMARY:
      return {
        ...state,
        loanSummary: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LOAN_SUMMARY:
      return {
        ...state,
        loanSummary: action.loanSummary,
        isFetchingSingle: false,
      };
    case REQUEST_GET_LOAN_SUMMARY_DRAFT:
      return {
        ...state,
        loanSummary: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LOAN_SUMMARY_DRAFT:
      return {
        ...state,
        loanSummary: action.loanSummary,
        isFetchingSingle: false,
      };
    case REQUEST_CREATE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSending: true,
      };
    case RECEIVE_CREATE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSending: false,
      };
    case REQUEST_GET_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isFetching: false,
        lenderLoanSummary: action.lenderLoanSummary.lenderLoanSummary,
        loan: action.lenderLoanSummary.loan,
      };
    case REQUEST_UPDATE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSaving: false,
        lenderLoanSummary: {
          ...state.lenderLoanSummary,
          status: action.lenderLoanSummary.status,
        },
      };
    case REQUEST_RESEND_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSending: true,
      };
    case RECEIVE_RESEND_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isSending: false,
      };
    case REQUEST_DELETE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        isDeleting: false,
      };
    case REQUEST_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE:
      return {
        ...state,
        isGenerating: true,
      };
    case RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE:
      return {
        ...state,
        isGenerating: false,
      };
    case RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE_ERROR:
    case RECEIVE_DELETE_LENDER_LOAN_SUMMARY_ERROR:
    case RECEIVE_UPDATE_LENDER_LOAN_SUMMARY_ERROR:
    case RECEIVE_GET_LOAN_SUMMARY_ERROR:
    case RECEIVE_CREATE_LOAN_SUMMARY_ERROR:
    case RECEIVE_DELETE_LOAN_SUMMARY_ERROR:
    case RECEIVE_GET_LOAN_SUMMARIES_ERROR:
    case RECEIVE_CREATE_LENDER_LOAN_SUMMARY_ERROR:
    case RECEIVE_GET_LENDER_LOAN_SUMMARY_ERROR:
    case RECEIVE_CREATE_LOAN_SUMMARY_DRAFT_ERROR:
    case RECEIVE_GET_LOAN_SUMMARY_DRAFT_ERROR:
    case RECEIVE_UPDATE_LOAN_SUMMARY_ERROR:
    case RECEIVE_RESEND_LENDER_LOAN_SUMMARY_ERROR:
      return {
        ...state,
        error: action.error,
        isPreviewing: false,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
        isSending: false,
        isGenerating: false,
      };
    default:
      return state;
  }
};
