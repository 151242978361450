import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { getQueryParams } from "@/utils/httpRequest";
import { showNotification } from "../actions/notification";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { setProcessingState } from "../reducers/modalConfirmation";

export const defaultLoanNoteFilters = {
  page: 1,
  size: 5,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const loanNoteService = createApi({
  reducerPath: "loanNoteApi",
  tagTypes: ["LoanNote"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanNotes: builder.query({
      query: ({ loanId, ...params }) => `loans/${loanId}/notes?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ loanId }) => ({ type: "LoanNote", loanId })), { type: "LoanNote", id: "LIST" }]
          : [{ type: "LoanNote", id: "LIST" }],
    }),
    addLoanNote: builder.mutation({
      query: ({ loanId, ...body }) => ({
        url: `loans/${loanId}/notes`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LoanNote", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_NOTE_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Note has been added to the loan!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLoanNote: builder.mutation({
      query: ({ loanId, loanNoteId, ...body }) => ({
        url: `loans/${loanId}/notes/${loanNoteId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "LoanNote", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_NOTE_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Note has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoanNote: builder.mutation({
      query: ({ loanId, loanNoteId }) => ({
        url: `loans/${loanId}/notes/${loanNoteId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LoanNote", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_NOTE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Note has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const { useGetLoanNotesQuery, useAddLoanNoteMutation, useUpdateLoanNoteMutation, useDeleteLoanNoteMutation } =
  loanNoteService;
