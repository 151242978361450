import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, MODAL, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { requestCloseSlideOver } from "../actions/slide_over";
import { requestCloseModal, requestOpenModal } from "../actions/modal";
import { lenderCallLogService } from "./lenderCallLogService";
import { setProcessingState } from "../reducers/modalConfirmation";

export const lendingCompanyService = createApi({
  reducerPath: "lendingCompanyApi",
  tagTypes: ["LendingCompany"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLendingCompanies: builder.query({
      query: params => `lending-companies?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [...result.items.map(({ id }) => ({ type: "LendingCompany", id })), { type: "LendingCompany", id: "LIST" }]
          : [{ type: "LendingCompany", id: "LIST" }],
    }),
    getLendingCompany: builder.query({
      query: id => `lending-companies/${id}`,
      providesTags: (result, error, id) => [{ type: "LendingCompany", id }],
    }),
    createLendingCompany: builder.mutation({
      query: body => ({
        url: "lending-companies",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LendingCompany", id: "LIST" }],
      async onQueryStarted(id, { getState, dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { isOpen: isModalOpen } = getState().modal;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.name} has been created!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          if (!isModalOpen) {
            dispatch(
              requestOpenModal(MODAL.CONFIRMATION, {
                title: "Lending Parameters",
                description: (
                  // eslint-disable-next-line react/react-in-jsx-scope
                  <>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    <p>Do you want to configure lending parameters now?</p>
                    {/* eslint-disable-next-line react/react-in-jsx-scope */}
                    <p className="mt-3 italic">
                      Please note that setting parameters allows SmartSort to filter and identify the most suitable
                      lending companies for the deal.
                    </p>
                  </>
                ),
                textButtonOk: "Yes",
                textButtonClose: "No",
                handleOk: () => {
                  dispatch(
                    requestOpenModal(MODAL.LENDING_PARAMETERS_WRAPPER, {
                      model: data,
                    }),
                  );
                },
              }),
            );
          }
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLendingCompany: builder.mutation({
      query: ({ lendingCompanyId: id, ...patch }) => ({
        url: `lending-companies/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "LendingCompany", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.name} has been updated!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompany: builder.mutation({
      query(id) {
        return {
          url: `lending-companies/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LendingCompany", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.name} has been deleted!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetLendingCompaniesQuery,
  useGetLendingCompanyQuery,
  useCreateLendingCompanyMutation,
  useUpdateLendingCompanyMutation,
  useDeleteLendingCompanyMutation,
} = lendingCompanyService;
