import React from "react";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";
import { Notification } from "@/components/notification/notification";

export function NotificationBus({ children }) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      content={(key, message) => <Notification id={key} message={message} />}
    >
      {children}
    </SnackbarProvider>
  );
}

NotificationBus.propTypes = {
  children: PropTypes.node.isRequired,
};
