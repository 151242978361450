import React from "react";
import { NextSeo } from "next-seo";
import { useUser } from "@auth0/nextjs-auth0/client";
import { Footer } from "@/components/intro/Footer";
import { Header } from "@/components/intro/Header";
import { Hero } from "@/components/intro/Hero";
import { PrimaryFeatures } from "@/components/intro/PrimaryFeatures";
import { FeaturesTable } from "@/components/intro/FeaturesTable";
import { KeyPoints } from "@/components/intro/KeyPoints";
import { Borrowers } from "@/components/intro/Borrowers";
import { NeedsList } from "@/components/intro/NeedsList";
import { Lenders } from "@/components/intro/Lenders";
import { DealFundview } from "@/components/intro/DealFundview";
import { CallToAction } from "@/components/intro/CallToAction";
import { Pricing } from "@/components/intro/Pricing";
import { Faqs } from "@/components/intro/Faqs";

function Home() {
  const { user } = useUser();

  return (
    <>
      <NextSeo
        title="Fundview"
        description="Deal flow software designed for CRE loan brokers."
        canonical="https://www.fundview.com/"
        openGraph={{
          url: "https://www.fundview.com/",
          title: "Fundview",
          description: "Deal flow software designed for CRE loan brokers.",
          type: "website",
          images: [
            {
              url: "https://www.fundview.com/fv_seo_icon.png",
              width: 585,
              height: 593,
              alt: "Fundview Logo",
              type: "image/png",
            },
          ],
          siteName: "Fundview",
        }}
      />

      <Header userConnected={user} />

      <main className="bg-white">
        <Hero />
        <PrimaryFeatures />
        <KeyPoints />
        <Borrowers />
        <NeedsList />
        <Lenders />
        <DealFundview />
        <FeaturesTable />
        <Pricing />
        <CallToAction />
        <Faqs />
      </main>
      <Footer />
    </>
  );
}

export default Home;
