import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_GET_PROPERTY_USES_ERROR,
  REQUEST_GET_PROPERTY_USES,
  RECEIVE_CREATE_PROPERTY_USE,
  RECEIVE_CREATE_PROPERTY_USE_ERROR,
  RECEIVE_GET_PROPERTY_USE,
  RECEIVE_GET_PROPERTY_USE_ERROR,
  RECEIVE_UPDATE_PROPERTY_USE,
  RECEIVE_UPDATE_PROPERTY_USE_ERROR,
  REQUEST_CLEAR_PROPERTY_USE,
  REQUEST_CREATE_PROPERTY_USE,
  REQUEST_GET_PROPERTY_USE,
  REQUEST_UPDATE_PROPERTY_USE,
  RECEIVE_GET_PROPERTY_USES,
  RECEIVE_DELETE_PROPERTY_USE,
  REQUEST_DELETE_PROPERTY_USE,
  RECEIVE_DELETE_PROPERTY_USE_ERROR,
} from "../constants/property_uses";

export const defaultPropertyUsesFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: "",
};

const defaultState = {
  propertyUses: [],
  propertyUse: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const propertyUses = (state = defaultState, action) => {
  let index;
  let newPropertyUses;

  switch (action.type) {
    case REQUEST_GET_PROPERTY_USES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_PROPERTY_USES:
      return {
        ...state,
        propertyUses: action.propertyUses,
        totalPages: action.propertyUses.totalPages,
        totalItems: action.propertyUses.totalItems,
        hasNextPage: action.propertyUses.hasNextPage,
        hasPreviousPage: action.propertyUses.hasPreviousPage,
        pageNumber: action.propertyUses.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_PROPERTY_USE:
      return {
        ...state,
        propertyUse: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_PROPERTY_USE:
      return {
        ...state,
        propertyUse: action.propertyUse,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_PROPERTY_USE:
      return {
        ...state,
        propertyUse: {},
      };
    case REQUEST_UPDATE_PROPERTY_USE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_PROPERTY_USE:
      index = findIndex(state.propertyUses, ["propertyUseId", action.propertyUse.propertyUseId]);
      if (index >= 0) {
        newPropertyUses = fill([...state.propertyUses], action.propertyUse, index, index + 1);
      }
      return {
        ...state,
        propertyUse: action.propertyUse,
        propertyUses: newPropertyUses || state.propertyUses,
        isSaving: false,
      };
    case REQUEST_CREATE_PROPERTY_USE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_PROPERTY_USE:
      return {
        ...state,
        propertyUse: action.propertyUse,
        isSaving: false,
      };
    case REQUEST_DELETE_PROPERTY_USE:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_PROPERTY_USE:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_PROPERTY_USE_ERROR:
    case RECEIVE_CREATE_PROPERTY_USE_ERROR:
    case RECEIVE_UPDATE_PROPERTY_USE_ERROR:
    case RECEIVE_GET_PROPERTY_USE_ERROR:
    case RECEIVE_GET_PROPERTY_USES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
