import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_GET_PROPERTY_TYPES_ERROR,
  REQUEST_GET_PROPERTY_TYPES,
  RECEIVE_GET_PROPERTY_TYPES,
  RECEIVE_GET_PROPERTY_TYPE_ERROR,
  RECEIVE_GET_PROPERTY_TYPE,
  REQUEST_GET_PROPERTY_TYPE,
  REQUEST_CLEAR_PROPERTY_TYPE,
  RECEIVE_UPDATE_PROPERTY_TYPE_ERROR,
  REQUEST_UPDATE_PROPERTY_TYPE,
  RECEIVE_UPDATE_PROPERTY_TYPE,
  RECEIVE_CREATE_PROPERTY_TYPE,
  RECEIVE_CREATE_PROPERTY_TYPE_ERROR,
  REQUEST_CREATE_PROPERTY_TYPE,
  RECEIVE_DELETE_PROPERTY_TYPE_ERROR,
  RECEIVE_DELETE_PROPERTY_TYPE,
  REQUEST_DELETE_PROPERTY_TYPE,
} from "../constants/property_types";

export const defaultPropertyTypesFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[6].id,
  searchTerm: "",
};

const defaultState = {
  propertyTypes: [],
  propertyType: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const propertyTypes = (state = defaultState, action) => {
  let index;
  let newPropertyTypes;

  switch (action.type) {
    case REQUEST_GET_PROPERTY_TYPES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: action.propertyTypes,
        totalPages: action.propertyTypes.totalPages,
        totalItems: action.propertyTypes.totalItems,
        hasNextPage: action.propertyTypes.hasNextPage,
        hasPreviousPage: action.propertyTypes.hasPreviousPage,
        pageNumber: action.propertyTypes.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_PROPERTY_TYPE:
      return {
        ...state,
        propertyType: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_PROPERTY_TYPE:
      return {
        ...state,
        propertyType: action.propertyType,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_PROPERTY_TYPE:
      return {
        ...state,
        propertyType: {},
      };
    case REQUEST_UPDATE_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_PROPERTY_TYPE:
      index = findIndex(state.propertyTypes, ["propertyTypeId", action.propertyType.propertyTypeId]);
      if (index >= 0) {
        newPropertyTypes = fill([...state.propertyTypes], action.propertyType, index, index + 1);
      }
      return {
        ...state,
        propertyType: action.propertyType,
        propertyTypes: newPropertyTypes || state.propertyTypes,
        isSaving: false,
      };
    case REQUEST_CREATE_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_PROPERTY_TYPE:
      return {
        ...state,
        propertyType: action.propertyType,
        isSaving: false,
      };
    case REQUEST_DELETE_PROPERTY_TYPE:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_PROPERTY_TYPE:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_PROPERTY_TYPE_ERROR:
    case RECEIVE_CREATE_PROPERTY_TYPE_ERROR:
    case RECEIVE_UPDATE_PROPERTY_TYPE_ERROR:
    case RECEIVE_GET_PROPERTY_TYPE_ERROR:
    case RECEIVE_GET_PROPERTY_TYPES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isDeleting: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
