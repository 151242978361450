import dynamic from "next/dynamic";

export const MODAL_COLLECTION = {
  USER: dynamic(() => import("@/components/contact/forms/contact_form"), { ssr: false }),
  CONFIRMATION: dynamic(() => import("@/components/modals/modal_confirmation"), { ssr: false }),
  NEED_LIST_FILES: dynamic(() => import("@/components/needs_list/need_list_file_record_list"), { ssr: false }),
  MY_NEED_LIST_FILES: dynamic(() => import("@/components/needs_list/my_need_list_file_record_list"), { ssr: false }),
  USER_OVERVIEW: dynamic(() => import("@/components/contact/forms/contact_view"), { ssr: false }),
  LENDER_RECORD_LIST: dynamic(() => import("@/components/lending_company/lender_record_list"), { ssr: false }),
  LOAN_SUMMARY_WRAPPER: dynamic(() => import("@/components/loan_summary/form/loan_summary_wrapper"), { ssr: false }),
  LENDER_LOAN_SUMMARY_RESPONSE_FORM: dynamic(
    () => import("@/components/loan_summary/form/loan_summary_response_form"),
    {
      ssr: false,
    },
  ),
  CONFIRMATION_V2: dynamic(() => import("@/components/modals/modal_confirmation_v2"), { ssr: false }),
  NEEDS_LIST_TEMPLATE_DOCUMENTS: dynamic(
    () => import("@/components/needs_list_templates/lists/needs_list_template_documents"),
    {
      ssr: false,
    },
  ),
  LENDER_CALL_LOG_NOTES: dynamic(() => import("@/components/lenders_call_log/list/lender_call_log_note_list"), {
    ssr: false,
  }),
  CREATABLE_ONLY_CONTACT_FORM: dynamic(() => import("@/components/contact/forms/creatable_contact_form"), {
    ssr: false,
  }),
  BROKER_BORROWER_NEEDS_LIST_FILES: dynamic(() => import("@/components/loan/broker/broker_needs_list_files"), {
    ssr: false,
  }),
  BORROWER_NEEDS_LIST_FILES: dynamic(() => import("@/components/loan/borrower/borrower_needs_list_files"), {
    ssr: false,
  }),
  LENDER_LOAN_SUMMARY_NOTIFICATIONS: dynamic(
    () => import("@/components/lenders_call_log/controls/lender_loan_summary_notifications"),
    {
      ssr: false,
    },
  ),
  LENDER_CRITERIA_FORM_WRAPPER: dynamic(() => import("@/components/lender_criteria/lender_criteria_form_wrapper"), {
    ssr: false,
  }),
  LENDER_OFFER_FORM_WRAPPER: dynamic(() => import("@/components/lender_offer/lender_offer_form_wrapper"), {
    ssr: false,
  }),
  BORROWER_APPLICATION_PARAMETERS: dynamic(
    () => import("@/components/loan/loan_form/borrower_application_parameters_form"),
    {
      ssr: false,
    },
  ),
  BORROWER_NEEDS_LIST_INSTRUCTIONS: dynamic(
    () => import("@/components/loan/broker/broker_needs_list_borrower_instructions"),
    {
      ssr: false,
    },
  ),
};

export const MODAL_SIZES = {
  SM: "SM",
  MD: "MD",
  LG: "LG",
  XL: "XL",
};
