import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import {
  RECEIVE_CREATE_LENDER_CALL_LOG_NOTE,
  RECEIVE_CREATE_LENDER_CALL_LOG_NOTE_ERROR,
  RECEIVE_DELETE_LENDER_CALL_LOG_NOTE,
  RECEIVE_DELETE_LENDER_CALL_LOG_NOTE_ERROR,
  RECEIVE_GET_LENDER_CALL_LOG_NOTES,
  RECEIVE_GET_LENDER_CALL_LOG_NOTES_ERROR,
  RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE,
  RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE_ERROR,
  REQUEST_CLEAR_LENDER_CALL_LOG_NOTES,
  REQUEST_CREATE_LENDER_CALL_LOG_NOTE,
  REQUEST_DELETE_LENDER_CALL_LOG_NOTE,
  REQUEST_GET_LENDER_CALL_LOG_NOTES,
  REQUEST_UPDATE_LENDER_CALL_LOG_NOTE,
} from "@/redux/constants/lender_call_log_notes";
import { SORT_OPTIONS } from "@/utils/applicationConstants";

export const defaultLenderCallLogNoteFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

const defaultState = {
  lenderCallLogNotes: [],
  error: null,
  isFetching: false,
  isSaving: false,
  idUpdating: null,
  isUpdating: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lenderCallLogNotes = (state = defaultState, action) => {
  let index;
  let newNotes;

  switch (action.type) {
    case REQUEST_GET_LENDER_CALL_LOG_NOTES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDER_CALL_LOG_NOTES:
      return {
        ...state,
        lenderCallLogNotes: action.lenderCallLogNotes.items,
        totalPages: action.lenderCallLogNotes.totalPages,
        totalItems: action.lenderCallLogNotes.totalItems,
        hasNextPage: action.lenderCallLogNotes.hasNextPage,
        hasPreviousPage: action.lenderCallLogNotes.hasPreviousPage,
        pageNumber: action.lenderCallLogNotes.pageNumber,
        isFetching: false,
      };
    case REQUEST_CLEAR_LENDER_CALL_LOG_NOTES:
      return {
        ...state,
        lenderCallLogNotes: [],
      };
    case REQUEST_UPDATE_LENDER_CALL_LOG_NOTE:
      return {
        ...state,
        isUpdating: true,
        idUpdating: action.lenderCallLogNoteId,
      };
    case RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE:
      index = findIndex(state.lenderCallLogNotes, [
        "lenderCallLogNoteId",
        action.lenderCallLogNote.lenderCallLogNoteId,
      ]);
      if (index >= 0) {
        newNotes = fill([...state.lenderCallLogNotes], action.lenderCallLogNote, index, index + 1);
      }
      return {
        ...state,
        lenderCallLogNotes: newNotes || state.lenderCallLogNotes,
        isUpdating: false,
        idUpdating: null,
      };
    case REQUEST_CREATE_LENDER_CALL_LOG_NOTE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDER_CALL_LOG_NOTE:
      return {
        ...state,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDER_CALL_LOG_NOTE:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LENDER_CALL_LOG_NOTE:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_LENDER_CALL_LOG_NOTE_ERROR:
    case RECEIVE_CREATE_LENDER_CALL_LOG_NOTE_ERROR:
    case RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE_ERROR:
    case RECEIVE_GET_LENDER_CALL_LOG_NOTES_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
        isUpdating: false,
        idUpdating: null,
      };
    default:
      return state;
  }
};
