import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_GET_STATES_ERROR,
  RECEIVE_GET_STATES,
  REQUEST_GET_STATES,
} from '../constants/parameters';

const defaultState = {
  states: [],
  state: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
};

export const defaultStateFilters = {
  page: 1,
  size: 5,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: '',
};

export const states = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_STATES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_STATES:
      return {
        ...state,
        states: action.states,
        isFetching: false,
      };
    case RECEIVE_GET_STATES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
