import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "@/utils/httpRequest";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "@/utils/analytics";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { requestCloseModal } from "../actions/modal";
import { setProcessingState } from "../reducers/modalConfirmation";

export const loanSummaryService = createApi({
  reducerPath: "loanSummaryApi",
  tagTypes: ["LoanSummary"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanSummaries: builder.query({
      query: ({ loanId, ...params }) => `loans/${loanId}/summaries?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ loanSummaryId }) => ({ type: "LoanSummary", loanSummaryId })),
              { type: "LoanSummary", id: "LIST" },
            ]
          : [{ type: "LoanSummary", id: "LIST" }],
    }),
    getLoanSummary: builder.query({
      query: ({ loanId, loanSummaryId }) => `loans/${loanId}/summaries/${loanSummaryId}`,
      providesTags: (result, error, { loanSummaryId }) => [{ type: "LoanSummary", loanSummaryId }],
    }),
    getLoanSummaryDraft: builder.query({
      query: ({ loanId, draftId }) => `loans/${loanId}/summaries/drafts/${draftId}`,
      providesTags: (result, error, { draftId }) => [{ type: "LoanSummary", draftId }],
      transformResponse: response => JSON.parse(response.data || "{}"),
    }),
    addLoanSummary: builder.mutation({
      query: ({ loanId, ...body }) => ({
        url: `loans/${loanId}/summaries`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LoanSummary", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    addLoanSummaryDraft: builder.mutation({
      query: ({ loanId, ...body }) => ({
        url: `loans/${loanId}/summaries/drafts`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LoanSummary", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary preview is generated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateLoanSummary: builder.mutation({
      query: ({ loanId, loanSummaryId, ...body }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { loanSummaryId }) => [{ type: "LoanSummary", loanSummaryId }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoanSummary: builder.mutation({
      query({ loanId, loanSummaryId }) {
        return {
          url: `loans/${loanId}/summaries/${loanSummaryId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LoanSummary", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Loan summary has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetLoanSummariesQuery,
  useGetLoanSummaryQuery,
  useGetLoanSummaryDraftQuery,
  useAddLoanSummaryMutation,
  useAddLoanSummaryDraftMutation,
  useUpdateLoanSummaryMutation,
  useDeleteLoanSummaryMutation,
} = loanSummaryService;
