import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_GET_LENDING_COMPANY,
  RECEIVE_GET_LENDING_COMPANY_ERROR,
  REQUEST_GET_LENDING_COMPANY,
  RECEIVE_GET_LENDING_COMPANIES_ERROR,
  REQUEST_GET_LENDING_COMPANIES,
  RECEIVE_GET_LENDING_COMPANIES,
  REQUEST_CLEAR_LENDING_COMPANY,
  RECEIVE_UPDATE_LENDING_COMPANY_ERROR,
  RECEIVE_UPDATE_LENDING_COMPANY,
  REQUEST_UPDATE_LENDING_COMPANY,
  RECEIVE_CREATE_LENDING_COMPANY_ERROR,
  RECEIVE_CREATE_LENDING_COMPANY,
  REQUEST_CREATE_LENDING_COMPANY,
  REQUEST_DELETE_LENDING_COMPANY,
  RECEIVE_DELETE_LENDING_COMPANY,
  RECEIVE_DELETE_LENDING_COMPANY_ERROR,
  REQUEST_GET_LENDING_COMPANY_FILTERS,
  RECEIVE_GET_LENDING_COMPANY_FILTERS,
  RECEIVE_GET_LENDING_COMPANY_FILTERS_ERROR,
} from "../constants/lending_companies";
import { RECEIVE_CREATE_USER } from "../constants/users";
import {
  RECEIVE_CREATE_ALL_LENDING_STATE,
  RECEIVE_CREATE_LENDING_STATE,
  RECEIVE_DELETE_ALL_LENDING_STATE,
  RECEIVE_DELETE_LENDING_STATE,
  RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE,
  RECEIVE_CREATE_LENDING_LOAN_TYPE,
  RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE,
  RECEIVE_DELETE_LENDING_LOAN_TYPE,
  RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE,
  RECEIVE_CREATE_LENDING_PROPERTY_TYPE,
  RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE,
  RECEIVE_DELETE_LENDING_PROPERTY_TYPE,
} from "../constants/lending_parameters";

export const defaultLendingCompaniesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
  loanTypes: [],
  states: [],
  propertyTypes: [],
  maxAmount: 0,
  maxLoanToValue: 0,
  maxLoanToConstruction: 0,
  debtServiceCoverageRatio: 0,
  guarantee: null,
  loanPrograms: [],
};

const defaultState = {
  lendingCompanies: [],
  lendingCompany: {},
  lendingCompanyFilters: [],
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isFetchingFilters: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lendingCompanies = (state = defaultState, action) => {
  let index;
  let newLendingCompanies;
  switch (action.type) {
    case REQUEST_GET_LENDING_COMPANIES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_COMPANIES:
      return {
        ...state,
        lendingCompanies: action.lendingCompanies.items,
        totalPages: action.lendingCompanies.totalPages,
        totalItems: action.lendingCompanies.totalItems,
        hasNextPage: action.lendingCompanies.hasNextPage,
        hasPreviousPage: action.lendingCompanies.hasPreviousPage,
        pageNumber: action.lendingCompanies.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LENDING_COMPANY:
      return {
        ...state,
        lendingCompany: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LENDING_COMPANY:
      return {
        ...state,
        lendingCompany: action.lendingCompany,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_LENDING_COMPANY:
      return {
        ...state,
        lendingCompany: {},
      };
    case REQUEST_UPDATE_LENDING_COMPANY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LENDING_COMPANY:
      index = findIndex(state.lendingCompanies, ["lendingCompanyId", action.lendingCompany.lendingCompanyId]);
      if (index >= 0) {
        newLendingCompanies = fill(
          [...state.lendingCompanies],
          { ...action.lendingCompany, lenders: state.lendingCompanies[index].lenders },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        lendingCompany: action.lendingCompany,
        lendingCompanies: newLendingCompanies || state.lendingCompanies,
        isSaving: false,
      };
    case RECEIVE_CREATE_LENDING_STATE:
    case RECEIVE_DELETE_LENDING_STATE:
    case RECEIVE_CREATE_ALL_LENDING_STATE:
    case RECEIVE_DELETE_ALL_LENDING_STATE:
    case RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE:
    case RECEIVE_CREATE_LENDING_LOAN_TYPE:
    case RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE:
    case RECEIVE_DELETE_LENDING_LOAN_TYPE:
    case RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE:
    case RECEIVE_CREATE_LENDING_PROPERTY_TYPE:
    case RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE:
    case RECEIVE_DELETE_LENDING_PROPERTY_TYPE:
      index = findIndex(state.lendingCompanies, ["lendingCompanyId", action.lendingParameter.lendingCompanyId]);
      if (index >= 0) {
        newLendingCompanies = fill(
          [...state.lendingCompanies],
          {
            ...state.lendingCompanies[index],
            lastUpdatedAt: new Date(),
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        lendingCompanies: newLendingCompanies || state.lendingCompanies,
      };
    case RECEIVE_CREATE_USER:
      index = findIndex(state.lendingCompanies, ["lendingCompanyId", action.user.lendingCompanyId]);
      if (index >= 0) {
        const lendingCompany = state.lendingCompanies[index];
        newLendingCompanies = fill(
          [...state.lendingCompanies],
          {
            ...lendingCompany,
            lenders: lendingCompany.lenders + 1,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        lendingCompanies: newLendingCompanies || state.lendingCompanies,
      };
    case REQUEST_CREATE_LENDING_COMPANY:
      return {
        ...state,
        isSaving: true,
        lendingCompany: {},
      };
    case RECEIVE_CREATE_LENDING_COMPANY:
      return {
        ...state,
        lendingCompany: action.lendingCompany,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_COMPANY:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LENDING_COMPANY:
      return {
        ...state,
        isDeleting: false,
      };
    case REQUEST_GET_LENDING_COMPANY_FILTERS:
      return {
        ...state,
        lendingCompanyFilters: [],
        isFetchingFilters: true,
      };
    case RECEIVE_GET_LENDING_COMPANY_FILTERS:
      return {
        ...state,
        lendingCompanyFilters: action.lendingCompanyFilters,
        isFetchingFilters: false,
      };
    case RECEIVE_GET_LENDING_COMPANY_FILTERS_ERROR:
    case RECEIVE_CREATE_LENDING_COMPANY_ERROR:
    case RECEIVE_UPDATE_LENDING_COMPANY_ERROR:
    case RECEIVE_GET_LENDING_COMPANY_ERROR:
    case RECEIVE_GET_LENDING_COMPANIES_ERROR:
    case RECEIVE_DELETE_LENDING_COMPANY_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isFetchingSingle: false,
        isFetchingFilters: false,
      };
    default:
      return state;
  }
};
