import React from "react";
import Link from "next/link";
import { Container } from "@/components/intro/Container";

export function Footer() {
  return (
    <footer className="bg-slate-50 sticky bottom-0">
      <Container>
        <div className="flex justify-center items-center space-x-8 border-t border-slate-400/10 py-10">
          <p className="flex mt-6 hover:underline text-sm text-slate-500 sm:mt-0">
            <Link href="/">Home</Link>
          </p>
          <p className="flex mt-6 hover:underline text-sm text-slate-500 sm:mt-0">
            <Link href="/blog/google-api-disclosure">Google API Disclosure</Link>
          </p>
          <p className="flex mt-6 hover:underline text-sm text-slate-500 sm:mt-0">
            <Link href="/policy">Privacy</Link>
          </p>
          <p className="flex mt-6 hover:underline text-sm text-slate-500 sm:mt-0">
            <a href="mailto:support@fundview.com">Contact</a>
          </p>
          <p className="flex mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} Fundview. All rights reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
}
