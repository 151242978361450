import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM,
  RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM_ERROR,
  RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS,
  RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR,
  RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM,
  RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM_ERROR,
  RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS,
  RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR,
  RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS,
  RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS_ERROR,
  REQUEST_CLEAR_LENDING_COMPANY_LOAN_PROGRAMS,
  REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAM,
  REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAMS,
  REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAM,
  REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAMS,
  REQUEST_GET_LENDING_COMPANY_LOAN_PROGRAMS,
} from '../constants/lending_company_loan_programs';

export const defaultLendingCompanyLoanProgramsFilters = {
  page: 1,
  size: 20,
  sortBy: SORT_OPTIONS[6].id,
  searchTerm: '',
};

const defaultState = {
  lendingCompanyLoanPrograms: [],
  error: null,
  isFetching: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  totalActiveItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lendingCompanyLoanPrograms = (state = defaultState, action) => {
  let index;
  let newLendingCompanyLoanPrograms;
  switch (action.type) {
    case REQUEST_GET_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        totalActiveItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        lendingCompanyLoanPrograms: action.lendingCompanyLoanPrograms.items,
        totalPages: action.lendingCompanyLoanPrograms.totalPages,
        totalItems: action.lendingCompanyLoanPrograms.totalItems,
        totalActiveItems: action.lendingCompanyLoanPrograms.totalActiveItems,
        hasNextPage: action.lendingCompanyLoanPrograms.hasNextPage,
        hasPreviousPage: action.lendingCompanyLoanPrograms.hasPreviousPage,
        pageNumber: action.lendingCompanyLoanPrograms.pageNumber,
        isFetching: false,
      };
    case REQUEST_CLEAR_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        totalActiveItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        lendingCompanyLoanPrograms: [],
      };
    case REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAM:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM:
      index = findIndex(state.lendingCompanyLoanPrograms, ['loanProgramId', action.lendingCompanyLoanProgram.loanProgramId]);
      if (index >= 0) {
        newLendingCompanyLoanPrograms = fill([...state.lendingCompanyLoanPrograms], action.lendingCompanyLoanProgram, index, index + 1);
      }
      return {
        ...state,
        lendingCompanyLoanPrograms: newLendingCompanyLoanPrograms || state.lendingCompanyLoanPrograms,
        totalActiveItems: state.totalActiveItems + 1,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAM:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM:
      index = findIndex(state.lendingCompanyLoanPrograms, ['loanProgramId', action.lendingCompanyLoanProgram.loanProgramId]);
      if (index >= 0) {
        newLendingCompanyLoanPrograms = fill([...state.lendingCompanyLoanPrograms], { ...state.lendingCompanyLoanPrograms[index], isActive: false }, index, index + 1);
      }
      return {
        ...state,
        lendingCompanyLoanPrograms: newLendingCompanyLoanPrograms || state.lendingCompanyLoanPrograms,
        totalActiveItems: state.totalActiveItems - 1,
        isSaving: false,
      };
    case REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        lendingCompanyLoanPrograms: state.lendingCompanyLoanPrograms.map((item) => ({
          ...item,
          isActive: true,
        })),
        totalActiveItems: state.totalItems,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS:
      return {
        ...state,
        lendingCompanyLoanPrograms: state.lendingCompanyLoanPrograms.map((item) => ({
          ...item,
          isActive: false,
        })),
        totalActiveItems: 0,
        isSaving: false,
      };
    case RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR:
    case RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR:
    case RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM_ERROR:
    case RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM_ERROR:
    case RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
      };
    default:
      return state;
  }
};
