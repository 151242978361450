import { fetchWithHeaders, HTTP_METHODS, parseErrorResponse, responseData } from "@/utils/httpRequest";

export function createLenderOfferFile(companyId, path, payload = {}) {
  return fetchWithHeaders(`/loans/${path.loanId}/lenders/${path.lenderId}/offers/${path.lenderOfferId}/files`, {
    method: HTTP_METHODS.POST,
    body: payload,
    includeFile: true,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
