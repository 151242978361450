import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import { MODAL, SLIDE_OVER, SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  REQUEST_GET_USERS,
  RECEIVE_GET_USERS_ERROR,
  RECEIVE_GET_USERS,
  REQUEST_CREATE_USER,
  RECEIVE_CREATE_USER,
  RECEIVE_CREATE_USER_ERROR,
  RECEIVE_GET_USER_ERROR,
  REQUEST_GET_USER,
  RECEIVE_GET_USER,
  REQUEST_CLEAR_USER,
  REQUEST_UPDATE_USER,
  RECEIVE_UPDATE_USER,
  RECEIVE_UPDATE_USER_ERROR,
} from "../constants/users";
import { REQUEST_OPEN_SLIDE_OVER } from "../constants/slide_over";
import { REQUEST_OPEN_MODAL } from "../constants/modal";

export const defaultUsersFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
  types: [],
  loanTypes: [],
  states: [],
  propertyTypes: [],
  maxAmount: 0,
  maxLoanToValue: 0,
  maxLoanToConstruction: 0,
  debtServiceCoverageRatio: 0,
  guarantee: null,
  usCitizen: null,
  bankruptcy: null,
  minFicoScore: 0,
  loanPrograms: [],
};

const defaultState = {
  users: [],
  user: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const users = (state = defaultState, action) => {
  let index;
  let newUsers;
  switch (action.type) {
    case REQUEST_OPEN_SLIDE_OVER:
      return {
        ...state,
        user:
          action.fragment === SLIDE_OVER.USER
            ? {
                ...state.user,
                ...action.options,
              }
            : state.user,
      };
    case REQUEST_OPEN_MODAL:
      return {
        ...state,
        user:
          action.fragment === MODAL.USER
            ? {
                ...state.user,
                ...action.options,
              }
            : state.user,
      };
    case REQUEST_CREATE_USER:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_USER:
      return {
        ...state,
        user: action.user,
        isSaving: false,
      };
    case REQUEST_UPDATE_USER:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_USER:
      index = findIndex(state.users, ["companyUserId", action.user.companyUserId]);
      if (index >= 0) {
        newUsers = fill([...state.users], action.user, index, index + 1);
      }
      return {
        ...state,
        user: action.user,
        users: newUsers || state.users,
        isSaving: false,
      };
    case REQUEST_GET_USERS:
      return {
        ...state,
        users: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_USERS:
      return {
        ...state,
        users: action.users.items,
        totalPages: action.users.totalPages,
        totalItems: action.users.totalItems,
        hasNextPage: action.users.hasNextPage,
        hasPreviousPage: action.users.hasPreviousPage,
        pageNumber: action.users.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_USER:
      return {
        ...state,
        user: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_USER:
      return {
        ...state,
        user: action.user,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_USER:
      return {
        ...state,
        user: {},
      };
    case RECEIVE_UPDATE_USER_ERROR:
    case RECEIVE_GET_USER_ERROR:
    case RECEIVE_CREATE_USER_ERROR:
    case RECEIVE_GET_USERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
