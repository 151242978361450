import React, { useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { notificationMessageShape } from "@/utils/propTypeShapes";
import { NOTIFICATION_VARIANTS } from "@/utils/applicationConstants";
import { Button } from "@/components/button/button";
import { closeNotification as closeNotificationAction } from "@/redux/actions/notification";
import styles from "./styles.module.sass";

export const Notification = React.forwardRef(({ key, message }, ref) => {
  const dispatch = useDispatch();

  const closeNotification = useCallback(() => {
    dispatch(closeNotificationAction(key));
  }, [dispatch, key]);

  const handleButton = useCallback(() => {
    if (message.options?.buttonAction) message.options?.buttonAction();
  }, [message]);

  const icon = useMemo(() => {
    // TODO: Add Info type
    switch (message.options.variant) {
      case NOTIFICATION_VARIANTS.SUCCESS:
        return <CheckCircleIcon className="h-8 w-8 text-green-400 bg-inherit" aria-hidden="true" />;
      case NOTIFICATION_VARIANTS.ERROR:
        return <XCircleIcon className="h-8 w-8 text-red-800 bg-inherit" aria-hidden="true" />;
      case NOTIFICATION_VARIANTS.WARNING:
        return <ExclamationCircleIcon className="h-8 w-8 text-yellow-400 bg-inherit" aria-hidden="true" />;
      default:
        return <CheckCircleIcon className="h-6 w-6 text-green-400 bg-inherit" aria-hidden="true" />;
    }
  }, [message]);

  return ReactDOM.createPortal(
    <div
      ref={ref}
      aria-live="assertive"
      className={classNames(
        styles.zIndex,
        "fixed inset-y-20 sm:inset-0 w-full flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start",
      )}
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <div className="max-w-sm w-full bg-white dark:bg-slate-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{icon}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900 dark:text-slate-100">{message.title}</p>
                <p className="mt-1 text-sm text-gray-500 dark:text-slate-300">{message.description}</p>
                {message.options?.buttonAction && (
                  <div className="mt-4 flex">
                    <Button label={message.options?.buttonLabel} onClick={handleButton} />
                  </div>
                )}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  onClick={closeNotification}
                  className="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#headlessui-portal-root"),
  );
});

Notification.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  key: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  message: notificationMessageShape,
};
