import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import { Container } from "@/components/intro/Container";
import { AnimateText } from "@/components/intro/AnimateText";
import { AnimateImage } from "@/components/intro/AnimateImage";

export function DealFundview() {
  return (
    <section id="key-points" aria-label="CRE Broker features" className="pt-20 pb-14 lg:py-48">
      <Container>
        <div className="flex flex-col-reverse lg:flex-row lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pr-10 lg:pt-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Still can&apos;t find the right lender to place your loan?
            </h2>
            <AnimateText className="mt-8 space-y-5">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                Just contact our team with the 1-page Loan Summary that you generated and we will do the legwork of
                finding a lender for you.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-gray-600 ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-indigo-600" />
                It&apos;s like having free back-office support to help add to your network of lending sources and get
                deals closed.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2">
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/fundview-help.png"
              alt="fundview preferred lenders"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
