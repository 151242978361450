import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

const CACHE_LOCATION = "sessionStorage";
const STORE_AUTH_STATE_IN_COOKIE = true;

const SCOPES = ["User.Read", "Contacts.Read", "openid", "profile", "email"];
const PROMPT_MODE = "select_account";

const TOTAL_CONTACTS_ENDPOINT = "https://graph.microsoft.com/v1.0/me/contacts/$count";

export const msalConfig = {
  auth: {
    clientId: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_AUTHORITY_URL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_REDIRECT_URL,
    interactionType: InteractionType.Popup,
  },
  cache: {
    cacheLocation: CACHE_LOCATION, // This configures where your cache will be stored
    storeAuthStateInCookie: STORE_AUTH_STATE_IN_COOKIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: SCOPES,
  prompt: PROMPT_MODE,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeContactsEndpoint: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_ENDPOINT_URL,
  totalContactsEndpoint: TOTAL_CONTACTS_ENDPOINT,
};

export const msalInstance = new PublicClientApplication(msalConfig);
