import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import { SORT_OPTIONS } from "@/utils/applicationConstants";
import {
  REQUEST_GET_LENDING_STATES,
  RECEIVE_GET_LENDING_STATES,
  RECEIVE_GET_LENDING_STATES_ERROR,
  REQUEST_CREATE_LENDING_STATE,
  RECEIVE_CREATE_LENDING_STATE,
  RECEIVE_CREATE_LENDING_STATE_ERROR,
  REQUEST_DELETE_LENDING_STATE,
  RECEIVE_DELETE_LENDING_STATE,
  RECEIVE_DELETE_LENDING_STATE_ERROR,
  REQUEST_CREATE_ALL_LENDING_STATE,
  RECEIVE_CREATE_ALL_LENDING_STATE,
  RECEIVE_DELETE_ALL_LENDING_STATE,
  REQUEST_DELETE_ALL_LENDING_STATE,
  RECEIVE_CREATE_ALL_LENDING_STATE_ERROR,
  RECEIVE_DELETE_ALL_LENDING_STATE_ERROR,
  REQUEST_GET_LENDING_LOAN_TYPES,
  RECEIVE_GET_LENDING_LOAN_TYPES,
  REQUEST_CREATE_LENDING_LOAN_TYPE,
  RECEIVE_CREATE_LENDING_LOAN_TYPE,
  REQUEST_DELETE_LENDING_LOAN_TYPE,
  RECEIVE_DELETE_LENDING_LOAN_TYPE,
  REQUEST_CREATE_ALL_LENDING_LOAN_TYPE,
  RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE,
  REQUEST_DELETE_ALL_LENDING_LOAN_TYPE,
  RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE,
  RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE_ERROR,
  RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE_ERROR,
  RECEIVE_CREATE_LENDING_LOAN_TYPE_ERROR,
  RECEIVE_DELETE_LENDING_LOAN_TYPE_ERROR,
  RECEIVE_GET_LENDING_LOAN_TYPES_ERROR,
  REQUEST_GET_LENDING_PROPERTY_TYPES,
  RECEIVE_GET_LENDING_PROPERTY_TYPES,
  REQUEST_CREATE_LENDING_PROPERTY_TYPE,
  RECEIVE_CREATE_LENDING_PROPERTY_TYPE,
  REQUEST_DELETE_LENDING_PROPERTY_TYPE,
  RECEIVE_DELETE_LENDING_PROPERTY_TYPE,
  REQUEST_CREATE_ALL_LENDING_PROPERTY_TYPE,
  RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE,
  REQUEST_DELETE_ALL_LENDING_PROPERTY_TYPE,
  RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE,
  RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE_ERROR,
  RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE_ERROR,
  RECEIVE_CREATE_LENDING_PROPERTY_TYPE_ERROR,
  RECEIVE_DELETE_LENDING_PROPERTY_TYPE_ERROR,
  RECEIVE_GET_LENDING_PROPERTY_TYPES_ERROR,
  REQUEST_CLEAR_LENDING_STATES,
  REQUEST_CLEAR_LENDING_LOAN_TYPES,
  REQUEST_CLEAR_LENDING_PROPERTY_TYPES,
} from '../constants/lending_parameters';

export const defaultLendingParametersFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[2].id,
  searchTerm: '',
};

const defaultState = {
  states: [],
  loanTypes: [],
  propertyTypes: [],
  error: null,
  isFetching: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lendingParameters = (state = defaultState, action) => {
  let index;
  let newParameters;
  switch (action.type) {
    case REQUEST_GET_LENDING_STATES:
      return {
        ...state,
        states: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        totalActiveItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_STATES:
      return {
        ...state,
        states: action.lendingParameters.items,
        totalPages: action.lendingParameters.totalPages,
        totalItems: action.lendingParameters.totalItems,
        totalActiveItems: action.lendingParameters.totalActiveItems,
        hasNextPage: action.lendingParameters.hasNextPage,
        hasPreviousPage: action.lendingParameters.hasPreviousPage,
        pageNumber: action.lendingParameters.pageNumber,
        isFetching: false,
      };
    case REQUEST_CREATE_LENDING_STATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_STATE:
      index = findIndex(state.states, ['stateId', action.lendingParameter.stateId]);
      if (index >= 0) {
        newParameters = fill([...state.states], action.lendingParameter, index, index + 1);
      }
      return {
        ...state,
        states: newParameters || state.states,
        totalActiveItems: state.totalActiveItems + 1,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_STATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_LENDING_STATE:
      index = findIndex(state.states, ['stateId', action.lendingParameter.stateId]);
      if (index >= 0) {
        newParameters = fill(
          [...state.states],
          {
            ...action.lendingParameter,
            isActive: false,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        states: newParameters || state.states,
        totalActiveItems: state.totalActiveItems - 1,
        isSaving: false,
      };
    case REQUEST_CREATE_ALL_LENDING_STATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_ALL_LENDING_STATE:
      return {
        ...state,
        states: state.states.map((item) => ({
          ...item,
          isActive: true,
        })),
        totalActiveItems: state.totalItems,
        isSaving: false,
      };
    case REQUEST_DELETE_ALL_LENDING_STATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_ALL_LENDING_STATE:
      return {
        ...state,
        states: state.states.map((item) => ({
          ...item,
          isActive: false,
        })),
        totalActiveItems: 0,
        isSaving: false,
      };
    case REQUEST_GET_LENDING_LOAN_TYPES:
      return {
        ...state,
        loanTypes: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        totalActiveItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_LOAN_TYPES:
      return {
        ...state,
        loanTypes: action.lendingParameters.items,
        totalPages: action.lendingParameters.totalPages,
        totalItems: action.lendingParameters.totalItems,
        totalActiveItems: action.lendingParameters.totalActiveItems,
        hasNextPage: action.lendingParameters.hasNextPage,
        hasPreviousPage: action.lendingParameters.hasPreviousPage,
        pageNumber: action.lendingParameters.pageNumber,
        isFetching: false,
      };
    case REQUEST_CREATE_LENDING_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_LOAN_TYPE:
      index = findIndex(state.loanTypes, ['loanTypeId', action.lendingParameter.loanTypeId]);
      if (index >= 0) {
        newParameters = fill([...state.loanTypes], action.lendingParameter, index, index + 1);
      }
      return {
        ...state,
        loanTypes: newParameters || state.loanTypes,
        totalActiveItems: state.totalActiveItems + 1,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_LENDING_LOAN_TYPE:
      index = findIndex([...state.loanTypes], ['loanTypeId', action.lendingParameter.loanTypeId]);
      if (index >= 0) {
        newParameters = fill(
          [...state.loanTypes],
          {
            ...action.lendingParameter,
            isActive: false,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        loanTypes: newParameters || state.loanTypes,
        totalActiveItems: state.totalActiveItems - 1,
        isSaving: false,
      };
    case REQUEST_CREATE_ALL_LENDING_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE:
      return {
        ...state,
        loanTypes: state.loanTypes.map((item) => ({
          ...item,
          isActive: true,
        })),
        totalActiveItems: state.totalItems,
        isSaving: false,
      };
    case REQUEST_DELETE_ALL_LENDING_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE:
      return {
        ...state,
        loanTypes: state.loanTypes.map((item) => ({
          ...item,
          isActive: false,
        })),
        totalActiveItems: 0,
        isSaving: false,
      };
    case REQUEST_GET_LENDING_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        totalActiveItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: action.lendingParameters.items,
        totalPages: action.lendingParameters.totalPages,
        totalItems: action.lendingParameters.totalItems,
        totalActiveItems: action.lendingParameters.totalActiveItems,
        hasNextPage: action.lendingParameters.hasNextPage,
        hasPreviousPage: action.lendingParameters.hasPreviousPage,
        pageNumber: action.lendingParameters.pageNumber,
        isFetching: false,
      };
    case REQUEST_CREATE_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_PROPERTY_TYPE:
      index = findIndex(state.propertyTypes, ['propertyTypeId', action.lendingParameter.propertyTypeId]);
      if (index >= 0) {
        newParameters = fill([...state.propertyTypes], action.lendingParameter, index, index + 1);
      }
      return {
        ...state,
        propertyTypes: newParameters || state.propertyTypes,
        totalActiveItems: state.totalActiveItems + 1,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_LENDING_PROPERTY_TYPE:
      index = findIndex(state.propertyTypes, ['propertyTypeId', action.lendingParameter.propertyTypeId]);
      if (index >= 0) {
        newParameters = fill(
          [...state.propertyTypes],
          {
            ...action.lendingParameter,
            isActive: false,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        propertyTypes: newParameters || state.propertyTypes,
        totalActiveItems: state.totalActiveItems - 1,
        isSaving: false,
      };
    case REQUEST_CREATE_ALL_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        propertyTypes: state.propertyTypes.map((item) => ({
          ...item,
          isActive: true,
        })),
        totalActiveItems: state.totalItems,
        isSaving: false,
      };
    case REQUEST_DELETE_ALL_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE:
      return {
        ...state,
        propertyTypes: state.propertyTypes.map((item) => ({
          ...item,
          isActive: false,
        })),
        totalActiveItems: 0,
        isSaving: false,
      };
    case REQUEST_CLEAR_LENDING_STATES:
      return {
        ...state,
        states: [],
      };
    case REQUEST_CLEAR_LENDING_LOAN_TYPES:
      return {
        ...state,
        loanTypes: [],
      };
    case REQUEST_CLEAR_LENDING_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: [],
      };
    case RECEIVE_CREATE_ALL_LENDING_STATE_ERROR:
    case RECEIVE_DELETE_ALL_LENDING_STATE_ERROR:
    case RECEIVE_CREATE_LENDING_STATE_ERROR:
    case RECEIVE_DELETE_LENDING_STATE_ERROR:
    case RECEIVE_GET_LENDING_STATES_ERROR:
    case RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE_ERROR:
    case RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE_ERROR:
    case RECEIVE_CREATE_LENDING_LOAN_TYPE_ERROR:
    case RECEIVE_DELETE_LENDING_LOAN_TYPE_ERROR:
    case RECEIVE_GET_LENDING_LOAN_TYPES_ERROR:
    case RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE_ERROR:
    case RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE_ERROR:
    case RECEIVE_CREATE_LENDING_PROPERTY_TYPE_ERROR:
    case RECEIVE_DELETE_LENDING_PROPERTY_TYPE_ERROR:
    case RECEIVE_GET_LENDING_PROPERTY_TYPES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
      };
    default:
      return state;
  }
};
