import React from "react";
import Image from "next/image";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Container } from "@/components/intro/Container";
import { AnimateText } from "@/components/intro/AnimateText";
import { AnimateImage } from "@/components/intro/AnimateImage";

export function PrimaryFeatures() {
  return (
    <section id="features" aria-label="CRE Broker features" className="relative overflow-hidden pt-20 pb-14 lg:py-48">
      <Image
        className="absolute"
        src="https://fvsiteprodst01.blob.core.windows.net/images/background-call-to-action.jpg"
        alt="background features"
        fill
        unoptimized
      />
      <Container className="relative">
        <div className="flex flex-col-reverse lg:flex-row-reverse lg:items-center">
          <div className="lg:w-1/2 pt-5 lg:pl-10 lg:pt-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
              Finally, a platform built with the broker in mind.
            </h2>
            <AnimateText className="mt-8 space-y-4">
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Simple interface with robust features for brokers to elevate your relationships and increase closings.
              </p>
              <p className="relative text-xl max-w-2xl text-lg tracking-tight text-white ml-8">
                <CheckCircleIcon className="absolute top-1 -left-8 w-6 h-6 mr-1 text-white" />
                Access to full suite of features created exclusively for commercial real estate mortgage brokers.
              </p>
            </AnimateText>
          </div>
          <AnimateImage className="lg:w-1/2">
            <Image
              className="rounded-md"
              src="https://fvsiteprodst01.blob.core.windows.net/images/finally.png"
              alt="broker working"
              width={1000}
              height={1000}
            />
          </AnimateImage>
        </div>
      </Container>
    </section>
  );
}
